import React, { useEffect, useState } from 'react'
import { FaStopCircle } from 'react-icons/fa'
import { FcSpeaker } from 'react-icons/fc'
import { useSpeechSynthesis } from 'react-speech-kit'

export default ({ value }) => {
  // const [voiceIndex, setVoiceIndex] = useState(null)
  const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis()

  useEffect(() => {
    return () => {
      cancel()
    }
  }, [value])

  if (!supported) {
    return null
  }

  return (
    <div>
      {speaking ? (
        <button onClick={() => cancel()}>
          <FaStopCircle className='text-lg text-red-500' />
        </button>
      ) : (
        <button
          onClick={() =>
            speak({
              text: value,
              voice: voices[2],
              rate: 0.9,
              pitch: 0.9
            })
          }
        >
          <FcSpeaker className='text-lg text-red-500' />
        </button>
      )}

      {/* <select
        className='bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 block w-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-32'
        name='voice'
        placeholder='Voice'
        value={voiceIndex || ''}
        onChange={e => {
          setVoiceIndex(e.target.value)
        }}
      >
        {voices.map((option, index) => (
          <option key={option.voiceURI} value={index}>
            {`${option.lang} - ${option.name} ${
              option.default ? '- Default' : ''
            }`}
          </option>
        ))}
      </select> */}
    </div>
  )
}
