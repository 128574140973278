import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import { HiMenu, HiLogout } from 'react-icons/hi'
import { connect } from 'react-redux'
import { Link } from '@reach/router'
import { IoMdLogOut } from 'react-icons/io'

import { signOut } from '../state/actions/auth'

function SettingsMenu ({ signOut }) {
  return (
    <Menu
      menuButton={
        <MenuButton>
          <HiMenu className='text-3xl text-gray-400' id='dropdownChild' />
        </MenuButton>
      }
      transition
      className='text-sm'
    >
      <Link to={`/app/usage`}>
        <MenuItem>Usage</MenuItem>
      </Link>

      <a href={`${process.env.GATSBY_STRIPE_BILLING_URL}`} target='_blank'>
        <MenuItem>Billing</MenuItem>
      </a>

      <Link to={`/app/subscribe`}>
        <MenuItem>Upgrade</MenuItem>
      </Link>

      <MenuItem
        onClick={() => {
          signOut()
        }}
        className='flex items-center text-red-500'
      >
        Logout <IoMdLogOut className='ml-1 ' />
      </MenuItem>
    </Menu>
  )
}

// Connect component to Redux mapStateToDispatch
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMenu)
