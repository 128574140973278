import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'

import { getResponse } from '../../state/actions/gpt3'
import countries from '../../data/countries'

const BusinessPlan = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)

    // Get Response from GPT-3
    // 1. The type of business you are looking to create – e.g. a retail store, a digital marketing agency, a software company, etc.
    // 2. Your target market – e.g. millennials, small businesses, enterprise clients, etc.
    // 3. Your desired outcome – e.g. increased sales, improved customer service, increased brand awareness, etc.
    // 4. Your budget – e.g. your available resources for marketing, advertising, product development, etc.
    // 5. Your timeline – e.g. when you’d like the business plan to be completed.
    // 6. Any other relevant details – e.g. the geographical area you’re looking to target, any existing competitors, etc.

    const phrase = `Write a business plan for ${data.companyName}, ${
      data.businessType
    }. Our target market is ${data.targetMarket} in ${data.location}.
    ${
      data.desiredOutcome &&
      `Our desired outcome from this business plan is ${data.desiredOutcome}.`
    } Our budget is ${data.budget}, what can we do?`

    const response = await getResponse({
      phrase,
      maxLength: 800,
      title: `Business Plan for ${data.companyName}`
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: `Business Plan for ${data.companyName}`,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  // Handle speech to text
  const handleTranscript = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    setValue('companyName', transcript)
  }

  const handleTranscript2 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    setValue('typeOfBusiness', transcript)
  }

  const handleTranscript3 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    setValue('targetMarket', transcript)
  }

  const handleTranscript4 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    setValue('desiredOutcome', transcript)
  }

  const handleTranscript5 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    setValue('budget', transcript)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Company Name */}
          <div className='w-full'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-xl text-gray-900 font-bold mb-1'>
                Name of your Business
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is the name of your business?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Milko Milk LLC'
                {...register('companyName', {
                  required: 'Please enter your company name'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>

            {errors.companyName?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.companyName?.message}
              </p>
            )}
          </div>

          {/* Target Locations */}
          <div className='mt-4'>
            <label htmlFor='tone' className='  mb-1 '>
              <h3 className='text-gray-900 font-bold'>Location</h3>
            </label>
            <select
              {...register('location', { required: true })}
              defaultValue='United States'
              className='p-3 w-full border border-gray-300 rounded mt-2 text-sm'
            >
              {/* Map through countries */}
              {countries.map((country, index) => (
                <option key={index} value={country.value}>
                  {country.value}
                </option>
              ))}
            </select>
          </div>

          {/* Type of Business */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Type of Business
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                The type of business you are looking to create
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Retail store, digital marketing agency, software company, etc.'
                {...register('typeOfBusiness', {
                  required: 'Please enter the type of business'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>

            {errors.typeOfBusiness?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.typeOfBusiness?.message}
              </p>
            )}
          </div>

          {/* Target Market */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Target Market
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is your target market?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Millennials, small businesses, enterprise clients, etc.'
                {...register('targetMarket', {
                  required: 'Please enter your target market'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript3} />
              </div>
            </div>

            {errors.targetMarket?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.targetMarket?.message}
              </p>
            )}
          </div>

          {/* Budget */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>Budget</h3>
              <p className='text-sm text-gray-500 mb-2'>
                Available resources for marketing, advertising, product
                development, etc.
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: $100,000'
                {...register('budget', {
                  required: 'Please enter your budget'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />
              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript5} />
              </div>
            </div>

            {errors.budget?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.budget?.message}
              </p>
            )}
          </div>

          {/* Desired Outcome */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Desired Outcome
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is your desired outcome
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Increase sales, improve customer service, increase brand awareness, etc.'
                {...register('desiredOutcome')}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript4} />
              </div>
            </div>
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(BusinessPlan)
