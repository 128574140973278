import React, { useState, useEffect } from 'react'
import { navigate, Link } from '@reach/router'
import { connect } from 'react-redux'
import { StaticImage } from 'gatsby-plugin-image'
import { sendPasswordReset } from '../../state/actions/auth'

import { MdArrowBackIosNew } from 'react-icons/md'
import Header from '../landing/header'

const ResetPassword = ({ isLoggedIn, sendPasswordReset }) => {
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/app/dashboard`)
    }
  }, [isLoggedIn])

  const handleSubmit = e => {
    e.preventDefault()
    sendPasswordReset(email).then(() => {
      navigate(`/app/login`)
    })
  }

  const navbarHeight = '96px'

  return (
    <div className='bg-gradient-to-b from-[#060317] via-[#02096E] via-[#030756] to-[#050534]'>
      <Header />

      <div
        className=' flex justify-center items-center  relative overflow-hidden'
        style={{ minHeight: `calc(100vh - ${navbarHeight} )` }}
      >
        <div className='text-white z-0 '>
          {/* Background Image - Vector Object*/}
          <div className='absolute w-full bottom-0 left-0'>
            <StaticImage
              src='../../images/landing/object.png'
              alt=''
              placeholder='blurred'
              layout='fullWidth'
              className='floatAnimation2'
            />
          </div>
        </div>

        <div className=' relative bg-white p-10 md:px-40 md:py-20  text-center rounded w-11/12 md:w-auto  md:h-auto flex flex-col justify-center z-10'>
          <div className='absolute top-10 left-10 text-gray-600  z-10 tracking-wide font-bold hover:text-blue-400 duration-50'>
            <Link to='/' className=' flex gap-2 items-center'>
              <MdArrowBackIosNew />
              BACK
            </Link>
          </div>

          {/* Icon */}
          <StaticImage
            src='../../images/icon1.png'
            alt=''
            placeholder='blurred'
            layout='fixed'
            width={60}
            className='mx-auto mb-2'
          />

          <h1 className='text-2xl font-bold'>Reset Password</h1>
          <p className='text-sm mt-2'>Please enter your email address</p>

          <form onSubmit={handleSubmit}>
            <div className='flex flex-col mt-5 '>
              <label
                htmlFor='email'
                className='text-left text-gray-500 text-sm'
              >
                Email
              </label>
              <input
                type='email'
                className='border border-gray-300 p-3 rounded mt-2'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>

            {/* Reset Password */}
            <div className='flex items-center mt-5'>
              <button
                className='bg-purple-500 text-white px-5 py-3 rounded mt-5 w-full disabled:opacity-50 '
                disabled={!email}
                type='submit'
              >
                Reset Password
              </button>
            </div>

            <p className='mt-5 text-sm '>
              Don't have an account?{' '}
              <Link to='/app/signup' className='text-blue-500'>
                Sign Up
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sendPasswordReset: email => dispatch(sendPasswordReset(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
