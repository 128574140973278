import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'
import { getResponse } from '../../state/actions/gpt3'

const SalesPitch = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)

    // Get Response from GPT-3
    // Write a prompt with the following information:
    // 1. A description of the occasion (e.g. "a special birthday")
    // 2. The recipient of the wishes (e.g. "for [name]")
    // 3. A request for the type of wishes (e.g. "Wishing you a wonderful birthday")
    // 4. An indication of the tone desired (e.g. "filled with joy and happiness")
    // 5. A closing sentiment (e.g. "Warmest wishes")

    const phrase = `Write a long wish for a ${data.occasion}, to ${data.recipient}. The tone should be ${data.tone}. End the wish with ${data.closing}.
    `

    const response = await getResponse({
      phrase,
      maxLength: 350,
      title: `${data.occasion} wishes for ${data.recipient}`
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: `${data.occasion} wishes for ${data.recipient}`,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  const handleTranscript = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('recipient', transcript)
  }

  const handleTranscript2 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('closing', transcript)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Occasion */}
          <div className='mt-4'>
            <label htmlFor='occasion' className='  mb-1 '>
              <h3 className='text-gray-900 text-xl font-bold'>
                What's The Occasion?
              </h3>
            </label>
            <select
              {...register('occasion', { required: true })}
              defaultValue='birthday'
              className='p-3 w-full border border-gray-300 rounded mt-2 text-sm'
            >
              <option value='birthday'>Birthday</option>
              <option value='anniversary'>Anniversary</option>
              <option value='graduation'>Graduation</option>
              <option value='wedding'>Wedding</option>
              <option value='baby shower'>Baby Shower</option>
              <option value='retirement'>Retirement</option>
              <option value='housewarming'>Housewarming</option>
              <option value='baptism'>Baptism</option>
              <option value='bar mitzvah'>Bar Mitzvah</option>
              <option value='christening'>Christening</option>
              <option value='promotion'>Promotion</option>
              <option value='engagement'>Engagement</option>
            </select>
          </div>

          {/* Recipient */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>Recipient</h3>
              <p className='text-sm text-gray-500 mb-2'>
                Who is the recipient of the wishes?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: John Doe'
                {...register('recipient', {
                  required: 'Please enter the recipient of the wishes'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>

            {errors.recipient?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.recipient?.message}
              </p>
            )}
          </div>

          {/* Tone */}
          <div className='mt-4'>
            <label htmlFor='tone' className='  mb-1 '>
              <h3 className='text-gray-900 text-l font-bold'>Tone</h3>
              <p className='text-sm text-gray-500 mb-2'>
                How would you like the wish to sound?
              </p>
            </label>
            <select
              {...register('tone', { required: true })}
              placeholder='Select a tone'
              defaultValue='loving'
              className='p-3 w-full border border-gray-300 rounded mt-2 text-sm'
            >
              <option value='loving'>Loving</option>
              <option value='friendly'>Friendly</option>
              <option value='funny'>Funny</option>
              <option value='romantic'>Romantic</option>
              <option value='professional'>Professional</option>
              <option value='family'>family</option>
              <option value='formal'>Formal</option>
              <option value='informal'>Informal</option>
              <option value='business'>Business</option>
            </select>
          </div>

          {/* Closing Statement */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Closing Statement
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What would you like to say to close your wish?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Best wishes'
                {...register('closing')}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(SalesPitch)
