import React from 'react'
import Modal from 'react-modal'
import { FaMicrophone } from 'react-icons/fa'
import SpeechToText from './speech-to-text'
import { ImCross } from 'react-icons/im'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  }
}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('yourAppElement')

function SpeechModal ({ handleTranscript }) {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  function openModal () {
    setIsOpen(true)
  }
  function closeModal () {
    setIsOpen(false)
  }
  return (
    <div>
      <FaMicrophone
        onClick={openModal}
        className='cursor-pointer text-gray-400 hover:text-green-500 duration-100 '
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        overlayClassName='overlayClassName'
      >
        <div className='p-10 text-center '>
          <SpeechToText
            handleTranscript={handleTranscript}
            closeModal={closeModal}
          />
        </div>
      </Modal>
    </div>
  )
}

export default SpeechModal
