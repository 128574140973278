import React from 'react'

export default function serviceLayout ({ children, fit }) {
  return (
    <div
      className={`${
        fit ? 'w-fit' : 'w-full'
      }  bg-white p-5 rounded  h-fit  shadow`}
    >
      {children}
    </div>
  )
}
