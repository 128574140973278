import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { WithContext as ReactTags } from 'react-tag-input'
import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'

import { getResponse } from '../../state/actions/gpt3'

const SalesLetter = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)

    // Get Response from GPT-3
    // Write a prompt with the following information:
    // 1. The target audience of the sales letter (e.g. small business owners, entrepreneurs, etc.)
    // 2. The purpose of the sales letter (e.g. to promote a product or service, to explain an offer or discount, etc.)
    // 3. Key points to be included in the sales letter (e.g. the benefits of the product or service, any special features, etc.).
    // 4. The desired tone of the sales letter (e.g. friendly, persuasive, informative, etc.).

    const phrase = `Write a long sales letter for ${
      data.companyName
    }, a company that sells ${data.product}. Our target market is ${
      data.targetMarket
    }.${
      data.points &&
      `Try to include the following key points ${data.points} in the ad.`
    } The tone of the letter should be ${data.tone}.`

    const response = await getResponse({
      phrase,
      maxLength: 450,
      title: `${data.companyName} - Sales Letter`
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: `${data.companyName} - Sales Letter`,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  const handleTranscript = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('companyName', transcript)
  }

  const handleTranscript2 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('product', transcript)
  }

  const handleTranscript3 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('targetMarket', transcript)
  }

  const handleTranscript4 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('points', transcript)
  }

  // Tags
  const [tags, setTags] = useState([])

  const handleDelete = i => {
    setTags(tags => {
      return tags.filter((tag, index) => index !== i)
    })
  }

  const handleAddition = tag => {
    setTags(tags => {
      return [...tags, tag]
    })
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Company Name */}
          <div className='w-full'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-xl text-gray-900 font-bold mb-1'>
                Name of your Business
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is the name of your business?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Milko Milk LLC'
                {...register('companyName', {
                  required: 'Please enter a topic for your article'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>

            {errors.companyName?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.companyName?.message}
              </p>
            )}
          </div>

          {/* Products or Services */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Product or Service
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What products or services are you trying to promote?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Chocolate Milk'
                {...register('product', {
                  required: 'Please enter your product or service'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>

            {errors.product?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.product?.message}
              </p>
            )}
          </div>

          {/* Target Market */}
          <div className='w-full mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Target Market
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Who is your target market?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: New Homeowners'
                {...register('targetMarket', {
                  required: 'Please enter your target market'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />
              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript3} />
              </div>
            </div>
            {errors.targetMarket?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.targetMarket?.message}
              </p>
            )}
          </div>

          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Key Points
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Key points to be included in your sales letter
              </p>
            </label>
            <div className='relative'>
              <input
                placeholder='Example: Reliable, Fast, Affordable'
                {...register('points', {
                  required: 'Please enter your target market'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />
              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript4} />
              </div>
            </div>
          </div>

          {/* Tone */}
          <div className='mt-4'>
            <label htmlFor='tone' className='  mb-1 '>
              <h3 className='text-gray-900 font-bold'>Tone of Voice</h3>
              <p className='text-sm text-gray-500 mb-2'>
                What tone of voice should Liyana use?
              </p>
            </label>
            <select
              {...register('tone', { required: true })}
              defaultValue='professional'
              className='p-3 w-full border border-gray-300 rounded mt-2 text-sm'
            >
              <option value='professional'>Professional</option>
              <option value='creative'>Creative</option>
              <option value='friendly'>Friendly</option>
              <option value='funny'>Funny</option>
              <option value='romantic'>Romantic</option>
              <option value='formal'>Formal</option>
              <option value='informal'>Informal</option>
              <option value='business'>Business</option>
            </select>
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(SalesLetter)
