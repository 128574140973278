import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

function App (props) {
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx

        ctx.restore()
        var fontSize = (height / 300).toFixed(2)
        ctx.font = fontSize + 'em sans-serif'
        ctx.textBaseline = 'top'
        var text = props.text,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2
        ctx.fillText(text, textX, textY)

        var fontSize2 = (height / 400).toFixed(2)
        ctx.font = fontSize2 + 'em sans-serif'
        var text2 = 'Used this month',
          textX2 = Math.round((width - ctx.measureText(text2).width) / 2),
          textY2 = height / 2
        ctx.fillText('Used this month', textX2, textY2 + 20)
        ctx.save()
      }
    }
  ]

  const data = {
    labels: ['Used', 'Available'],
    datasets: [
      {
        data: props.data,
        fill: false,
        lineTension: 0.1,
        backgroundColor: ['lightblue', 'lightgrey']
      }
    ]
  }
  //  Create new object from props.data according to day of the month

  return <Doughnut data={data} plugins={plugins} />
}

export default App
