// Login
import React from 'react'
import { toast } from 'react-toastify'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail
} from 'firebase/auth'

import { loadUserData } from './db'

import app from 'gatsby-plugin-firebase-v9.0'

const auth = getAuth(app)

// Autologin with Local Storage
export const autoLogin = () => {
  return dispatch => {
    const auth = JSON.parse(localStorage.getItem('auth'))
    if (auth) {
      dispatch(dispatchLogin(auth))
    }
  }
}

// Dispatch Login
export const dispatchLogin = ({ user, token }) => {
  return async dispatch => {
    // Dispatch login
    dispatch({
      type: 'LOGIN',
      data: { user, token }
    })

    // Load user data
    dispatch(loadUserData())

    // Toast Success with First Name
    toast.success(
      <p className=' text-center'>
        Welcome back{' '}
        <span className='capitalize'>{user.displayName.split(' ')[0]}</span>!
      </p>,
      {
        toastId: 'login'
      }
    )
    // Save to localstorage
    typeof window !== 'undefined' &&
      localStorage.setItem('auth', JSON.stringify({ user, token }))
  }
}

export const loginWithGoogle = () => {
  return dispatch => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential.accessToken
        // The signed-in user info.
        const user = result.user
        // Dispatch Login
        dispatch(dispatchLogin({ user, token }))
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.customData?.email
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error)
        // ...
      })
  }
}

// Login with email
export const loginWithEmail = ({ email, password }) => {
  return dispatch => {
    if (email === '' || password === '') {
      toast.error('Please fill in all fields')
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then(userCredential => {
          // Signed in
          const user = userCredential.user
          const token = userCredential.accessToken
          // Dispatch Login
          dispatch(dispatchLogin({ user, token }))
        })
        .catch(error => {
          const errorCode = error.code
          const errorMessage = error.message
          toast.error(
            <p className='capitalize'>
              Error: {errorCode?.replace(/-/g, ' ').split('/')[1]}
            </p>
          )
        })
    }
  }
}

// Sign Up with email
export const signUpWithEmail = ({ firstName, lastName, email, password }) => {
  return dispatch => {
    if (email === '' || password === '') {
      toast.error('Please fill in all fields')
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async userCredential => {
          // Signed in
          const user = userCredential.user
          const token = userCredential.accessToken
          // Update user profile
          await updateProfile(auth.currentUser, {
            displayName: `${firstName} ${lastName}`
          })
          // Dispatch Login
          dispatch(dispatchLogin({ user, token }))
        })
        .catch(error => {
          const errorCode = error.code
          toast.error(
            <p className='capitalize'>
              Error:{' '}
              {errorCode
                ? errorCode?.replace(/-/g, ' ').split('/')[1]
                : 'Something went wrong. Please try again later.'}
            </p>
          )
          // ..
        })
    }
  }
}

// Sign Out
export const signOut = () => {
  return dispatch => {
    auth.signOut()

    // Dispatch USER_LOGOUT Clears Redux State!
    dispatch({
      type: 'USER_LOGOUT'
    })
    typeof window !== 'undefined' && localStorage.removeItem('auth')
    toast(<p className='capitalize text-center'>Successfully Signed Out!</p>)
  }
}

// Send Password Reset Email
export const sendPasswordReset = email => {
  return async dispatch => {
    const auth = getAuth()

    await sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success(
          <p className='text-center'>
            Password reset email sent to {email.toLowerCase()}
          </p>
        )
      })
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log(errorCode, errorMessage)
        toast.error(
          <p className='capitalize'>
            Error: {errorMessage?.replace(/-/g, ' ').split('/')[1]}
          </p>
        )
      })
  }
}
