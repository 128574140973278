import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'

import { getResponse } from '../../state/actions/gpt3'

const SalesPitch = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)

    // Get Response from GPT-3
    // Write a prompt with the following information:
    // -The product or service you are selling
    // -Your target market
    // -The benefits of your product/service (eg: saves time, saves money, etc.)
    // -The competitive advantages of your product/service (eg: better quality, better price, etc.)
    // -Any unique features of your product/service (eg: free shipping, free returns, etc.)
    // -Your call to action

    const phrase = `Write a sales pitch for ${data.companyName}, a company that sells ${data.product}. 
    Our target market is ${data.targetMarket}.The benefits of our product are ${data.benefits}. 
    The competitive advantages of our product are ${data.advantages}.
    The unique features of our product are ${data.features}. Our call to action is ${data.callToAction}. `

    const response = await getResponse({
      phrase,
      maxLength: 800,
      title: `Sales Pitch for ${data.companyName}`
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: `Sales Pitch for ${data.companyName}`,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  const handleTranscript = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('companyName', transcript)
  }

  const handleTranscript2 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('product', transcript)
  }

  const handleTranscript3 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('targetMarket', transcript)
  }

  const handleTranscript4 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('benefits', transcript)
  }

  const handleTranscript5 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('advantages', transcript)
  }

  const handleTranscript6 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('features', transcript)
  }

  const handleTranscript7 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('callToAction', transcript)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Company Name */}
          <div className='w-full'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-xl text-gray-900 font-bold mb-1'>
                Name of your Business
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is the name of your business?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Milko Milk LLC'
                {...register('companyName', {
                  required: 'Please enter your company name'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>

            {errors.companyName?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.companyName?.message}
              </p>
            )}
          </div>

          {/* Product */}
          <div className='w-full mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Product or Service
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What product or service are you selling?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Milk'
                {...register('product', {
                  required: 'Please enter your products or services'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />
              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>

            {errors.product?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.product?.message}
              </p>
            )}
          </div>

          {/* Target Market */}
          <div className='w-full mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Target Market
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Who is your target market?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: New Parents'
                {...register('targetMarket', {
                  required: 'Please enter your target market'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />
              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>
            {errors.targetMarket?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.targetMarket?.message}
              </p>
            )}
          </div>

          {/* Benefits */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>Benefits</h3>
              <p className='text-sm text-gray-500 mb-2'>
                What are the benefits of your product or service?
              </p>
            </label>
            <div className='relative'>
              <input
                placeholder='Example: Saves Time, Saves Money'
                {...register('benefits')}
                className='p-3 w-full border border-gray-300 rounded'
              />
              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript4} />
              </div>
            </div>
          </div>

          {/* Competitive Advantage */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Competitive Advantages
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What are the competitive advantages of your product or service?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Better Quality, Better Price, etc.'
                {...register('advantages')}
                className='p-3 w-full border border-gray-300 rounded'
              />
              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript5} />
              </div>
            </div>
          </div>

          {/* Product/Service Features */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Unique Features
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What are the unique features of your product or service?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Free Shipping, Free Returns'
                {...register('features')}
                className='p-3 w-full border border-gray-300 rounded'
              />
              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript6} />
              </div>
            </div>
          </div>

          {/* Target Market */}
          <div className='w-full mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Call to Action
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is your call to action?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Call us now!'
                {...register('callToAction', {
                  required: 'Please enter your call to action'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />
              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript7} />
              </div>
            </div>
            {errors.callToAction?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.callToAction?.message}
              </p>
            )}
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(SalesPitch)
