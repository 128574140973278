import { format, lastDayOfMonth, differenceInDays, addDays } from 'date-fns'

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 8,
        font: {
          size: 10,
          family: 'Montserrat',
          weight: 400,
          lineHeight: 1.5
        },
        includeBounds: true
      }
    },
    y: {
      stacked: false,
      grid: {
        display: true
      },
      ticks: {
        font: {
          size: 10,
          family: 'Montserrat',
          weight: 400,
          lineHeight: 1.5
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    }
  }
}

export const getDays = () => {
  const today = new Date()
  const firstDateOfMonth = format(today, 'yyyy-MM-01')
  const lastDateOfMonth = format(lastDayOfMonth(today), 'yyyy-MM-dd')

  const days = []
  let dateStart = firstDateOfMonth
  const dateEnd = lastDateOfMonth
  let noOfDays = differenceInDays(new Date(dateEnd), new Date(dateStart))
  console.log(noOfDays)

  while (noOfDays >= 0) {
    days.push(format(new Date(dateStart), 'dd MMM'))
    dateStart = format(addDays(new Date(dateStart), 1), 'yyyy-MM-dd')
    noOfDays--
  }
  return days
}
