import React from 'react'
import Layout from './layout'
import { connect } from 'react-redux'
import { FaUser, FaLock } from 'react-icons/fa'
import { Link } from 'gatsby'
import { sendPasswordReset } from '../state/actions/auth'
import { FaCrown, FaStar } from 'react-icons/fa'

function Profile ({ user, subscription, sendPasswordReset, totalUsage }) {
  const userInitials = user?.displayName
    .split(' ')
    .map(name => name[0])
    .join('')
    .toUpperCase()

  return (
    <Layout>
      <div className='flex flex-col'>
        {/* Name */}
        <div className='flex items-center'>
          {/* Display Image */}
          {!user.photoURL ? (
            // If user has no photo, show initials
            <button className='rounded-full mr-5 w-14 h-14 border p-1 flex items-center justify-center bg-gray-50 '>
              <span className='text-blue-500 font-bold text-sm'>
                {userInitials}
              </span>
            </button>
          ) : (
            <img
              src={user.photoURL}
              className='rounded-full mr-5 w-14 h-14 border p-1'
              alt={userInitials}
              referrerPolicy='no-referrer'
            />
          )}

          <h1 className='text-2xl font-bold '>{user.displayName}</h1>
        </div>

        {/* Navigation */}
        <div className='flex flex-col'>
          <ul className='flex mt-5 items-center gap-5 text-sm'>
            <li className='flex items-center border rounded bg-lightgray shadow px-2 md:px-5 py-1 hover:bg-gray-100 cursor-pointer'>
              <FaUser className='mr-3 text-xs' color='lightgray' />
              {subscription.status === 'inactive' ? (
                <Link to={`/app/subscribe`}>Upgrade</Link>
              ) : (
                <a
                  href={`${process.env.GATSBY_STRIPE_BILLING_URL}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Billing
                </a>
              )}
            </li>

            {/* Reset Password */}
            <li
              role='button'
              className='flex items-center border rounded bg-lightgray shadow px-2 md:px-5 py-1 hover:bg-gray-100 cursor-pointer'
              onClick={() => {
                sendPasswordReset(user.email)
              }}
            >
              <FaLock className='mr-3 text-xs' color='lightgray' />
              Reset Password
            </li>
          </ul>
        </div>
      </div>

      <table className='mt-10 w-full'>
        <tbody className='bg-white'>
          <tr>
            <td className='px-2 md:px-5 py-4 whitespace-no-wrap border-b border-gray-200'>
              <div className='flex items-center'>
                <div className='ml-4'>
                  <div className='text-sm font-medium leading-5 text-gray-900'>
                    Email:
                  </div>
                </div>
              </div>
            </td>

            <td className='px-2 md:px-5 py-4 whitespace-no-wrap border-b border-gray-200 '>
              <div className='flex items-center'>
                <div className='ml-4'>
                  <div className='text-sm font-medium leading-5 text-gray-900'>
                    {user.email}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td className='px-2 md:px-5 py-4 whitespace-no-wrap border-b border-gray-200'>
              <div className='flex items-center'>
                <div className='ml-4'>
                  <div className='text-sm font-medium leading-5 text-gray-900'>
                    Subscription Status:
                  </div>
                </div>
              </div>
            </td>

            <td className='px-2 md:px-5 py-4 whitespace-no-wrap border-b border-gray-200 '>
              <div className='flex items-center'>
                <div className='ml-4'>
                  <div className='text-sm font-medium leading-5 text-gray-900'>
                    {subscription.status === 'inactive' ? (
                      <span className='flex items-center  '>Free Plan</span>
                    ) : (
                      <span className='rounded font-bold py-2 rounded text-xs  items-center flex'>
                        <span className='font-bold text-gray-700'>Pro</span>
                        <FaCrown className='ml-2 ' color='gold' />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td className='px-2 md:px-5 py-4 whitespace-no-wrap border-b border-gray-200'>
              <div className='flex items-center'>
                <div className='ml-4'>
                  <div className='text-sm font-medium leading-5 text-gray-900'>
                    Words Per Month:
                  </div>
                </div>
              </div>
            </td>

            <td className='px-2 md:px-5 py-4 whitespace-no-wrap border-b border-gray-200 '>
              <div className='flex items-center'>
                <div className='ml-4'>
                  <div className='text-sm font-medium leading-5 text-gray-900 flex items-center'>
                    {subscription.plan.wordCount.toLocaleString()}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Link
        to='/app/usage'
        className='text-xs hover:text-blue-600 duration-100'
      >
        <p className='mt-5'>
          Words Remaining:{' '}
          {(subscription.plan.wordCount - totalUsage).toLocaleString()}
        </p>
      </Link>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth.auth.user,
    subscription: state.db.db.subscription,
    totalUsage: state.db?.db?.usage?.totalUsage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sendPasswordReset: email => dispatch(sendPasswordReset(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
