import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { WithContext as ReactTags } from 'react-tag-input'

import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'

import { getResponse } from '../../state/actions/gpt3'

const SalesPitch = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)

    // Get Response from GPT-3
    // Write a prompt with the following information:
    // 1. The couple's names
    // 2. A brief description of the couple's relationship
    // 3. A list of the couple's shared interests and hobbies
    // 4. A few anecdotes about the couple's history together
    // 5. A few words about the couple's future together

    const phrase = `Write a wedding speech celebrating the marriage of ${
      data.coupleNames
    }, who have been together for ${data.relationship}. ${
      data.interests && `Their common interests include ${data.interests}.`
    }`

    const response = await getResponse({
      phrase,
      maxLength: 400,
      title: `Wedding Speech for ${data.coupleNames}`
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: `Wedding Speech for ${data.coupleNames}`,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  const handleTranscript = transcript => {
    setValue('coupleNames', transcript)
  }

  const handleTranscript2 = transcript => {
    setValue('relationship', transcript)
  }

  const handleTranscript3 = transcript => {
    setValue('interests', transcript)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Couple's Names */}
          <div className='w-full'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-xl text-gray-900 font-bold mb-1'>
                Couple's Names
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Who are the happy couple?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: John and Jane Doe'
                {...register('coupleNames', {
                  required: 'Please enter the names of the happy couple'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>

            {errors.coupleNames?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.coupleNames?.message}
              </p>
            )}
          </div>

          {/* Couple's Relationship */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Couple's Relationship
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                How long have they been together?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Since high school'
                {...register('relationship', {
                  required: 'Please enter a topic for your article'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>

            {errors.relationship?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.relationship?.message}
              </p>
            )}
          </div>

          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Shared Interests
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What are the couple's shared interests?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Jogging, cycling, games'
                {...register('interests', {
                  required: "Please enter the couple's shared interests"
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript3} />
              </div>
            </div>
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(SalesPitch)
