import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { options } from './utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

function App (props) {
  const data = {
    labels: props.labels,
    datasets: [
      {
        data: props.data,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        pointBorderColor: '#111',
        pointBorderWidth: 1.5,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  }
  return <Line options={options} data={data} />
}

export default App
