import React, { useState } from 'react'
import Modal from 'react-modal'
import { HiTranslate } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'
import Select from 'react-select'
import translateList from '../data/languages'
import axios from 'axios'
import { toast } from 'react-toastify'
import { FaRegCopy } from 'react-icons/fa'
import { decode } from 'html-entities'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '80%',
    height: '100%',
    maxWidth: '660px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  }
}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('yourAppElement')

function TranslateModal ({ text }) {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [language, setLanguage] = useState(translateList[0])
  const [translatedText, setTranslatedText] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(translatedText)
    toast('Copied to clipboard!', {
      autoClose: 1000
    })
  }

  function openModal () {
    setIsOpen(true)
  }

  function closeModal () {
    setIsOpen(false)
  }

  //   Write function to translate text from Google Translate API
  const handleTranslate = () => {
    setLoading(true)
    axios
      .get(
        `https://translation.googleapis.com/language/translate/v2?key=${
          process.env.GATSBY_GOOGLE_TRANSLATE_API_KEY
        }&q=${text.trim().split('\n').join('<br/>')}&target=${
          language.value
        }&format=text`,
        {
          responseEncoding: 'utf8'
        }
      )
      .then(res => {
        setTranslatedText(res.data.data.translations[0].translatedText)
      })
      .finally(() => {
        setLoading(false)
      })
      .catch(err => {
        setError(true)
        console.log(err)
      })
  }

  return (
    <div>
      <HiTranslate
        onClick={openModal}
        className='cursor-pointer text-gray-400 hover:text-green-500 duration-100 '
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        overlayClassName='overlayClassName'
      >
        {/* Close Button */}
        <div className='absolute top-0 left-0 p-4 '>
          <button
            className='text-gray-400 hover:text-green-500 duration-100 text-2xl'
            onClick={closeModal}
          >
            <AiOutlineClose />
          </button>
        </div>

        <div
          className='items-start text-gray-800 mb-10 mt-5 p-10 lg:p-15 whitespace-pre-line w-full h-full'
          style={{ maxHeight: '100%', overflowY: 'scroll' }}
        >
          <div className='flex items-center  mb-4'>
            <h1 className='text-2xl font-bold capitalize'>Translate</h1>
            {/* Copy to clipboard */}
            {translatedText && (
              <button className='ml-3'>
                <FaRegCopy
                  onClick={copyToClipboard}
                  className=' hover:text-orange-400 text-gray-400 text-sm'
                />
              </button>
            )}
          </div>
          {/* Language Selector Made with React-Select, includes all languages from Google Translate */}
          <div className='mb-4 text-sm flex items-center'>
            <Select
              options={translateList}
              onChange={language => setLanguage(language)}
              value={language}
              placeholder='Select a language'
              isSearchable={true}
              className='w-full'
            />
            <button
              className='
                bg-green-500
                hover:bg-green-600
                text-white
                font-bold
                py-2
                px-4
                rounded
                ml-2
                '
              onClick={handleTranslate}
            >
              Translate
            </button>
          </div>

          {/* Show Translated or Original Text */}
          <p>
            {error ? (
              <p className='text-center my-10'>
                Sorry, there was a problem translating this text. <br /> Please
                try again later.
              </p>
            ) : (
              decode(translatedText.split('<br/>').join('\n')) || text.trim()
            )}
          </p>
        </div>
      </Modal>
    </div>
  )
}

export default TranslateModal
