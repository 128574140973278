import 'regenerator-runtime'
import React from 'react'
import { FaMicrophone } from 'react-icons/fa'
import SpeechRecognition, {
  useSpeechRecognition
} from 'react-speech-recognition'
import { BiReset } from 'react-icons/bi'
import { ImCross } from 'react-icons/im'
import LottieIcon from './lottieIcon'
import robotIcon from '../images/icons/robot.json'

const Dictaphone = ({ handleTranscript, closeModal }) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
} = useSpeechRecognition()

  const handleGenerate = () => {
    handleTranscript(transcript)
    SpeechRecognition.stopListening()
    resetTranscript()
    closeModal()
  }

  const handleToggleMic = () => {
    if (listening) {
      SpeechRecognition.stopListening()
    } else {
      SpeechRecognition.startListening({ continuous: true })
    }
  }

  SpeechRecognition.startListening({ continuous: true })

  const handleCloseModal = async () => {
    SpeechRecognition.stopListening()
    await resetTranscript()
    closeModal()
  }

  if (!browserSupportsSpeechRecognition) {
    return null
  }

  return (
    <div className='flex flex-col'>
      <button
        onClick={handleCloseModal}
        className='absolute top-5 right-5 text-gray-400 text-l hover:text-red-600 duration-100'
      >
        <ImCross />
      </button>

      <h2 className='text-4xl font-bold mb-5'>
        <LottieIcon icon={robotIcon} width='w-16' />

        {listening ? 'Listening..' : 'Tap To Start Speaking'}
      </h2>

      {transcript ? (
        <p className='bg-white p-10 rounded'>{transcript}</p>
      ) : listening ? (
        <p className='bg-white p-10 rounded text-gray-300'>Start speaking..</p>
      ) : (
        ''
      )}

      {!listening && (
        <button
          onClick={e => {
            e.preventDefault()
            handleToggleMic()
          }}
          className='mt-3'
        >
          <FaMicrophone
            className={`text-8xl p-4 mx-auto rounded-full border border-gray-400 m-auto  ${
              listening ? 'text-green-500' : 'text-red-400'
            }`}
          />
        </button>
      )}

      {listening && (
        <div className='flex justify-center items-center'>
          <button
            onClick={resetTranscript}
            disabled={!transcript}
            className={` mt-3 bg-gray-200 text-gray-600 px-5 py-3 rounded disabled:opacity-50 w-32 mx-1 flex justify-center items-center duration-300`}
          >
            <span>
              <BiReset className='text-xs mr-1 ' />
            </span>
            Reset
          </button>

          <button
            onClick={handleGenerate}
            disabled={!transcript}
            className={` mt-3 bg-blue-500 text-white px-5 py-3 rounded disabled:opacity-50 w-32 mx-1 duration-300`}
          >
            Done
          </button>
        </div>
      )}
    </div>
  )
}
export default Dictaphone
