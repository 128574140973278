import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import Navbar from './navbar'

const Layout = ({ padding, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const navbarHeight = '96px'

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      <div className='flex'>
        <Navbar />
        <main
          className={`bg-gray-50 w-full overflow-y-auto ${
            padding !== 0 && `px-5 py-5 md:p-10`
          }`}
          style={{
            height: `calc(100vh - ${navbarHeight})`,
            listStyle: 'none'
          }}
        >
          {children}
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
