import React, { useState } from 'react'
import Select from 'react-select'
import languages from '../../data/languages'
import { connect } from 'react-redux'
import { setLanguage } from '../../state/actions/utils'

const options = languages.map(language => {
  return {
    value: language.value,
    label: language.label
  }
})

export const LanguageSelector = ({ language, setLanguage }) => {
  const handleChange = e => {
    setLanguage(e.value)
  }

  return (
    <div className='w-64 mt-5 md:mt-0  h-10 mx-auto md:ml-10 '>
      <Select
        options={options}
        className=''
        isSearchable={true}
        onChange={handleChange}
        value={options.find(option => option.value === language)}
      />
    </div>
  )
}

// Redux

const mapStateToProps = state => ({
  language: state.utils.language
})

const mapDispatchToProps = dispatch => ({
  setLanguage: language => dispatch(setLanguage(language))
})

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)
