import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'
import { getResponse } from '../../state/actions/gpt3'

const FacebookAd = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)

    // Get Response from GPT-3
    // Write a prompt with the following information:
    // 1. The target audience of the ad (age, gender, location, interests, etc.)
    // 2. Specific objectives for the ad (such as driving traffic to a website, increasing sales, etc.)
    // 3. A clear call-to-action (such as "Buy Now," "Sign Up for a Free Trial," etc.)
    // 4. A concise, attention-grabbing headline.
    // 5. A brief description of the product or service being advertised.

    const phrase = `Write a Facebook ad for ${data.companyName} that sells ${data.description}. The ad should be targeted to ${data.targetAudience}. The objective of the ad is to ${data.objectives} and should include the call to action - ${data.callToAction}. The ad should include a headline and a brief description of the product or service being advertised.`

    const response = await getResponse({
      phrase,
      maxLength: 450,
      title: `${data.companyName} - Facebook Ad`
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: `${data.companyName} - Facebook Ad`,
      response: response
    })

    // Set loading state
    loadingHandler(false)
  }

  const handleTranscript = transcript => {
    setValue('companyName', transcript)
  }

  const handleTranscript2 = transcript => {
    setValue('description', transcript)
  }

  const handleTranscript3 = transcript => {
    setValue('targetAudience', transcript)
  }

  const handleTranscript4 = transcript => {
    setValue('objectives', transcript)
  }

  const handleTranscript5 = transcript => {
    setValue('callToAction', transcript)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Company Name */}
          <div className='w-full'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-xl text-gray-900 font-bold mb-1'>
                Name of your Business
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is the name of your business?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Milko Milk LLC'
                {...register('companyName', {
                  required: 'Please enter a topic for your article'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>

            {errors.companyName?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.companyName?.message}
              </p>
            )}
          </div>

          {/* Company Description */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Product/Service Description
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                A brief description of your product or service
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Fresh milk delivered to your door'
                {...register('description', {
                  required: 'Please enter a description of your product/service'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>

            {errors.description?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.description?.message}
              </p>
            )}
          </div>

          {/* Target Audience */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Target Audience
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What's the target audience for this ad?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: People who like to drink milk'
                {...register('targetAudience', {
                  required: 'Please enter your target audience'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript3} />
              </div>
            </div>

            {errors.targetAudience?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.targetAudience?.message}
              </p>
            )}
          </div>

          {/* Objectives */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Objectives
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What are the objectives of this ad?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: To increase sales of milk'
                {...register('objectives', {
                  required: 'Please enter your objectives'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript4} />
              </div>
            </div>

            {errors.objectives?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.objectives?.message}
              </p>
            )}
          </div>

          {/* Call to Action */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Call to Action
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is the call to action for this ad?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Shop now'
                {...register('callToAction', {
                  required: 'Please enter your call to action'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />
              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript5} />
              </div>
            </div>
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(FacebookAd)
