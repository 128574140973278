import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { WithContext as ReactTags } from 'react-tag-input'

import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'

import { getResponse } from '../../state/actions/gpt3'

const BusinessPlan = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)

    // Get Response from GPT-3
    // Write a prompt with the following information:
    // 1. The desired outcome of the email (e.g. encourage readers to take action, increase engagement, promote a product or service, etc.)
    // 2. The target audience (e.g. existing customers, potential customers, influencers, etc.)
    // 3. The key message or theme of the email (e.g. special offer, limited time discount, new product launch, etc.)
    // 4. Any specific details to be included in the email (e.g. product name, link to website, discount code, etc.)
    // 5. Any additional information that will help Open AI understand the context and goal of the email (e.g. company values, brand voice, etc.)

    const phrase = `Write a marketing email for ${data.companyName} to ${data.desiredOutcome}. The target audience is ${data.targetAudience}. 
    The key message is ${data.message}. The specific details are ${data.specificDetails}. 
    The additional information is ${data.additionalInformation}.`

    const response = await getResponse({
      phrase,
      maxLength: 1000,
      title: `Marketing Email for ${data.companyName}`
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: `Marketing Email for ${data.companyName}`,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  const handleTranscript = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('companyName', transcript)
  }

  const handleTranscript2 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('desiredOutcome', transcript)
  }

  const handleTranscript3 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('targetAudience', transcript)
  }

  const handleTranscript4 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('messages', transcript)
  }

  const handleTranscript5 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('specificDetails', transcript)
  }

  const handleTranscript6 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('additionalInfo', transcript)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Company Name */}
          <div className='w-full'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-xl text-gray-900 font-bold mb-1'>
                Name of your Business
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is the name of your business?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Milko Milk LLC'
                {...register('companyName', {
                  required: 'Please enter a topic for your article'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>

            {errors.description?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.description?.message}
              </p>
            )}
          </div>

          {/* Desired Outcome of the Email */}
          <div className='w-full mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Desired Outcome
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is the desired outcome of the email?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Increase engagement, promote a product or service, etc.'
                {...register('desiredOutcome', {
                  required: 'Please enter your desired outcome'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>

            {errors.description?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.description?.message}
              </p>
            )}
          </div>

          {/* Target Audience */}
          <div className='w-full mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Target Audience
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Who is the target audience for the email?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Existing customers, potential customers, influencers, etc.'
                {...register('targetAudience', {
                  required: 'Please enter your target audience'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript3} />
              </div>
            </div>

            {errors.targetAudience?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.targetAudience?.message}
              </p>
            )}
          </div>

          {/* Key Messages */}
          <div className='w-full mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Key Messages
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What are the key messages you want to convey in the email?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Existing customers, potential customers, influencers, etc.'
                {...register('messages')}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript4} />
              </div>
            </div>
          </div>

          {/* Any specific details to be included in the email */}
          <div className='w-full mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Specific Details
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Are there any specific details you want to include in the email?
              </p>
            </label>

            <div className='relative'>
              <textarea
                lines='3'
                placeholder='Example: Include a link to a specific product, include a coupon code, etc.'
                {...register('specificDetails')}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript5} />
              </div>
            </div>
          </div>

          {/* Any additional information that will help Open AI understand the context and goal of the email (e.g. company values, brand voice, etc.) */}
          <div className='w-full mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Additional Information
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Are there any additional details that will help Liyana
                understand the context and goal of the email?
              </p>
            </label>

            <div className='relative'>
              <textarea
                lines='3'
                placeholder='Example: Company values, brand voice, etc.'
                {...register('additionalInfo')}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript6} />
              </div>
            </div>
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(BusinessPlan)
