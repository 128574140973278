import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import { ToastContainer } from 'react-toastify'
import { connect } from 'react-redux'

import PrivateRoute from '../components/privateRoute'

// Auth
import Login from '../components/auth/login'
import SignUp from '../components/auth/signUp'
import ResetPassword from '../components/auth/resetPassword'

// User
import Profile from '../components/profile'
import Usage from '../components/usage'
import Dashboard from '../components/dashboard'

// Payments
import Subscribe from '../components/subscribe'
import Success from '../components/success'

// Services
import Chat from '../components/services/chat'
import Article from '../components/services/article'
import BusinessPlan from '../components/services/businessPlan'
import SalesPitch from '../components/services/salesPitch'
import MarketingEmail from '../components/services/marketingEmail'
import WebsiteContent from '../components/services/websiteContent'
import BirthdayWishes from '../components/services/birthdayWishes'
import SeasonsGreetings from '../components/services/seasonsGreetings'
import FacebookAd from '../components/services/facebookAd'
import GoogleAd from '../components/services/googleAd'
import YoutubeVideoScript from '../components/services/youtubeVideoScript'
import ThankYouNote from '../components/services/thankYou'
import WeddingSpeech from '../components/services/weddingSpeech'
import Resume from '../components/services/resume'
import CoverLetter from '../components/services/coverLetter'
import BusinessContract from '../components/services/businessContract'
import RecommendationLetter from '../components/services/recommendationLetter'
import SalesLetter from '../components/services/salesLetter'

import PublicRoute from '../components/publicRoute'
import { autoLogin } from '../state/actions/auth'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import app from 'gatsby-plugin-firebase-v9.0'
import { navigate } from 'gatsby'

const auth = getAuth(app)

const App = ({ autoLogin }) => {
  // useEffect to autoLogin
  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        // User is signed in.
        autoLogin()
      } else {
        navigate('/app/login')
      }
    })
  }, [])

  return (
    <div>
      <ToastContainer position='top-center' />
      <Router>
        {/* Private Routes */}
        {/* --User */}
        <PrivateRoute component={Dashboard} path='/app/dashboard/' />
        <PrivateRoute component={Usage} path='/app/usage/' />
        <PrivateRoute component={Profile} path='/app/profile/' />

        {/* Payments */}
        <PrivateRoute component={Subscribe} path='/app/subscribe/' />
        <PrivateRoute component={Success} path='/app/success/' />

        {/* --Services */}
        <PrivateRoute component={Chat} path='/app/chat/' />
        <PrivateRoute component={Article} path='/app/article/' />
        <PrivateRoute component={BusinessPlan} path='/app/business-plan/' />
        <PrivateRoute component={SalesPitch} path='/app/sales-pitch/' />
        <PrivateRoute component={MarketingEmail} path='/app/marketing-email/' />
        <PrivateRoute component={WebsiteContent} path='/app/website-content/' />
        <PrivateRoute component={BirthdayWishes} path='/app/birthday/' />
        <PrivateRoute
          component={SeasonsGreetings}
          path='/app/seasons-greetings/'
        />
        <PrivateRoute component={FacebookAd} path='/app/facebook-ad/' />
        <PrivateRoute component={GoogleAd} path='/app/google-ad/' />
        <PrivateRoute
          component={YoutubeVideoScript}
          path='/app/youtube-video-script/'
        />
        <PrivateRoute component={ThankYouNote} path='/app/thank-you-note/' />
        <PrivateRoute component={WeddingSpeech} path='/app/wedding-speech/' />
        <PrivateRoute component={Resume} path='/app/resume/' />
        <PrivateRoute component={CoverLetter} path='/app/cover-letter/' />
        <PrivateRoute
          component={BusinessContract}
          path='/app/business-contract/'
        />

        <PrivateRoute
          component={BusinessContract}
          path='/app/business-letter/'
        />
        <PrivateRoute
          component={RecommendationLetter}
          path='/app/recommendation-letter/'
        />
        <PrivateRoute component={SalesLetter} path='/app/sales-letter/' />

        {/* Public Routes */}
        <PublicRoute component={SignUp} path='/app/signup/' />
        <PublicRoute component={Login} path='/app/login/' />
        <PublicRoute component={ResetPassword} path='/app/reset-password/' />

        <Login default />
      </Router>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    autoLogin: () => dispatch(autoLogin())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
