import React, { useState } from "react"
import { Link } from "@reach/router"
import { BsSearch } from "react-icons/bs"
import { connect } from "react-redux"
import Layout from "./layout"
import services, { categories } from "../data/services"

export const Dashboard = ({ user }) => {
  const [search, setSearch] = useState("")
  const [category, setCategory] = useState("all")

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  const handleCategory = e => {
    setSearch("")
    setCategory(e.target.value.toLowerCase())
  }

  const filteredServices = services.filter(service => {
    if (search) {
      return service.name.toLowerCase().includes(search.toLowerCase())
    } else if (category === "all") {
      return service
    } else {
      return service.category === category
    }
  })

  //   Function to capitalize first letter of a string

  const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const name = user.auth.user
    ? capitalize(user.auth.user.displayName.split(" ")[0])
    : "Guest"

  return (
    <Layout>
      <div className="h-full">
        <h1 className="text-xl font-bold text-center">
          Hi {name}, how can I help?
        </h1>
        {/* Add an input to search */}

        <div className="pr-10 mx-auto relative w-fit">
          <input
            type="text"
            className="border py-2 px-5  w-full md:w-96 rounded mt-3 h-10 text-sm drop-shadow"
            placeholder="Search for something..."
            value={search}
            onChange={handleSearch}
          />
          {/* Search Button */}

          <button
            className="bg-purple-400
          text-white rounded mt-3  absolute right--10 h-10 w-10 absolute right-0"
          >
            <BsSearch className="mx-auto text-lg" />
          </button>
        </div>

        {/* Add tabs to change categories */}
        <div className="flex  items-center mt-4 relative max-w-fit mx-auto overflow-x-scroll lg:overflow-x-hidden">
          {categories.map((cat, index) => (
            <button
              key={index}
              className={` 
              flex flex-col items-center justify-center px-8 py-1 m-2 rounded-lg shadow capitalize duration-50 text-sm ${
                cat === category
                  ? "text-white bg-gradient-to-b from-purple-500  to-purple-600 "
                  : "text-white bg-gradient-to-br from-purple-300 to-purple-400 hover:from-purple-400 hover:to-purple-500"
              }  `}
              onClick={handleCategory}
              value={cat}
            >
              {cat}
            </button>
          ))}
        </div>

        <div className="flex flex-wrap  justify-center md:justify-start mt-5">
          {filteredServices.map(service => {
            return (
              <div
                key={service.name}
                className="flex flex-col text-center md:text-left items-center justify-center w-full md:w-1/2 xl:w-1/3 p-2"
              >
                {/* Card with icon on the left */}
                <Link
                  to={service.path}
                  className="flex flex-col md:flex-row items-center gap-2 md:gap-4  bg-white shadow-md hover:shadow-lg rounded-lg px-5 py-8 w-full duration-100 cursor-pointer"
                >
                  <div className="flex  items-center">
                    {/* Icon Image */}
                    <img
                      src={service.image}
                      className="w-8 h-8"
                      alt={service.name}
                    />
                  </div>
                  <div>
                    <h1 className=" font-bold flex items-center justify-center md:justify-start">
                      {service.name}
                      {/* {service.name === 'Chat' && (
                        <span className='ml-1 text-sm text-orange-500'>
                          (Premium)
                        </span>
                      )} */}
                    </h1>

                    <p className="text-xs md:text-sm text-gray-500">
                      {service.description}
                    </p>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => ({
  user: state.auth,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
