import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'

import { getResponse } from '../../state/actions/gpt3'

const SalesPitch = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)

    // Get Response from GPT-3
    // Write a prompt with the following information:
    // 1. The job title or type of position you are applying for.
    // 2. A brief description of the skills and qualifications you possess that make you a good fit for the job.
    // 3. A list of any relevant work experience and education that you have.
    // 4. Your desired salary and benefits package.
    // 5. Your contact information and any other relevant information that would be appropriate to include in a resume.

    const phrase = `Write a resume for the position of ${data.title}. My skills and qualifications include ${data.skills}. I have ${data.experience}. I am looking for a salary of ${data.salary}. My contact information is ${data.contact}.`

    console.log(phrase)

    const response = await getResponse({
      phrase,
      maxLength: 800,
      title: `Resume`
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: `Resume`,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  const handleTranscript = transcript => {
    setValue('title', transcript)
  }

  const handleTranscript2 = transcript => {
    setValue('skills', transcript)
  }

  const handleTranscript3 = transcript => {
    setValue('experience', transcript)
  }

  const handleTranscript4 = transcript => {
    setValue('salary', transcript)
  }

  const handleTranscript5 = transcript => {
    setValue('contact', transcript)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* */}
          <div className='w-full'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-xl text-gray-900 font-bold mb-1'>
                Job Title or Position
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                The job title or type of position you are applying for
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Marketing Manager'
                {...register('title', {
                  required: 'Please enter your job title or position'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>

            {errors.title?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.title?.message}
              </p>
            )}
          </div>

          {/* Skills & Qualifications */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Skills and Qualifications
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                A brief description of the skills and qualifications you possess
              </p>
            </label>

            <div className='relative'>
              <textarea
                placeholder='Example: Bachelor’s degree in Marketing'
                {...register('skills', {
                  required: 'Please enter your skills and qualifications'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>
            {errors.skills?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.skills?.message}
              </p>
            )}
          </div>

          {/* Work Experience */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Work Experience
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                A list of any relevant work experience that you have
              </p>
            </label>

            <div className='relative'>
              <textarea
                placeholder='Example: I have 5 years of experience with companies such as Google, Facebook, and Amazon.'
                {...register('experience', {
                  required: 'Please enter your work experience'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript3} />
              </div>
            </div>
            {errors.experience?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.experience?.message}
              </p>
            )}
          </div>

          {/* Salary */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Salary and Benefits
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Your desired salary and benefits
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: $100,000 per year and health insurance'
                {...register('salary', {
                  required: 'Please enter your salary and benefits'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript4} />
              </div>
            </div>

            {errors.salary?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.salary?.message}
              </p>
            )}
          </div>

          {/* Contact Information */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Contact Information
              </h3>
            <p className='text-sm text-gray-500 mb-2'>
                Your contact information
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: 555-555-5555 or email@domain.com'
                {...register('contact', {
                  required: 'Please enter your contact information'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript5} />
              </div>
            </div>
            {errors.contact?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.contact?.message}
              </p>
            )}
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(SalesPitch)
