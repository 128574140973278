import React, { useState } from 'react'
import ServiceLayout from './serviceLayout'
import ResponseLayout from './responseLayout'

import ArticleForm from '../forms/article'
import { toast } from 'react-toastify'
import Editor from './editor'
import Loader from '../loaders/responseLoader'

// Icons
import { FaRegCopy } from 'react-icons/fa'
import Layout from '../layout'
import blogIcon from '../../images/services/blog.png'
import { StaticImage } from 'gatsby-plugin-image'
import TextToSpeech from '../text-to-speech'
import TranslateModal from '../translateModal'
import LanguageSelector from './languageSelector'

export default function Article () {
  const [openResponse, setOpenResponse] = useState('')
  const [responseTitle, setResponseTitle] = useState('')

  const [responseLoading, setResponseLoading] = useState(false)

  const handleResponse = response => {
    if (!response) {
      setOpenResponse('')
      setResponseTitle('')
      return
    }

    const responseTitleSplit = response.title
      .split(' ')
      .map(word => {
        return word[0].toUpperCase() + word.substring(1)
      })
      .join(' ')

    setResponseTitle(responseTitleSplit)
    setOpenResponse(response.response)
  }

  // Function to copy openResponse to clipboard
  const copyToClipboard = () => {
    const text = `${responseTitle} ${openResponse}`
    navigator.clipboard.writeText(text)
    toast('Copied to clipboard!', {
      autoClose: 1000
    })
  }

  const navbarHeight = '96px'
  const footerHeight = '36px'

  return (
    <Layout>
      <div
        style={{
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - 5rem )`
        }}
      >
        <div className='md:flex items-center mb-10 '>
          <div className='flex items-center justify-center md:justify-start'>
            <img src={blogIcon} alt='article' className='w-8 mr-5' />
            <h1 className='font-bold text-2xl text-gray-900'>Blog Article</h1>
          </div>

          <LanguageSelector />
        </div>

        <div className='grid gap-4 grid-cols-1 lg:grid-cols-2 h-full'>
          <ServiceLayout>
            <ArticleForm
              responseHandler={handleResponse}
              loadingHandler={setResponseLoading}
              isLoading={responseLoading}
            />
          </ServiceLayout>

          <ResponseLayout>
            <div
              className='p-5 md:p-10 text-gray-400 flex flex-col items-center relative h-full'
              style={{ whiteSpace: 'pre-line' }}
            >
              {!openResponse && !responseLoading && (
                <div className='m-auto text-center'>
                  <StaticImage
                    alt=''
                    src='../../images/icons/ai.png'
                    className='w-10 '
                  />
                  <p className='text-l  mb-2  text-center mt-2'>
                    Fill out the fields and click generate!
                  </p>
                </div>
              )}

              {!openResponse && responseLoading && <Loader />}

              {openResponse && (
                <>
                  <div className='text-md flex self-end gap-3 cursor-pointer duration-150 absolute top-5 '>
                    <TranslateModal title={responseTitle} text={openResponse} />
                    <TextToSpeech value={openResponse} />

                    <FaRegCopy
                      onClick={copyToClipboard}
                      className=' hover:text-orange-400'
                    />
                  </div>

                  <div className='items-start w-full h-full text-gray-800 mb-10 mt-5 '>
                    <Editor openResponse={openResponse.trim()} />
                  </div>

                  <div className=' text-xs flex self-start  gap-5	 absolute top-5 select-none	'>
                    <p>Words: {openResponse.split(' ').length}</p>
                    <p>Characters: {openResponse.length}</p>
                  </div>
                </>
              )}
            </div>
          </ResponseLayout>
        </div>
      </div>
    </Layout>
  )
}
