import * as React from 'react'
import Navdrawer from './navdrawer2'
import { connect } from 'react-redux'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from '@reach/router'
import SettingsMenu from './settingsMenu'
import { FaCrown, FaStar } from 'react-icons/fa'

const Header = ({ isLoggedIn, user, subscription }) => {
  const userInitials = user?.displayName
    .split(' ')
    .map(name => name[0])
    .join('')
    .toUpperCase()

  return (
    <header className='flex items-center justify-between shadow bg-white text-black px-5 h-24 border'>
      <Link to='/app/dashboard/'>
        <StaticImage
          src='../images/logo/logo-b.png'
          alt='Liyana'
          placeholder='blurred'
          layout='fixed'
          width={140}
        />
      </Link>

      {/* Only show if logged in */}
      {isLoggedIn && (
        <div className='flex items-center'>
          {/* Subscribe */}
          {subscription?.status === 'inactive' && (
            <Link
              to='/app/subscribe'
              className='rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white font-bold py-2 px-10 rounded text-xs  items-center hidden md:flex'
            >
              Upgrade <FaStar className='ml-1' />
            </Link>
          )}
          {subscription?.status === 'active' && (
            <p className='rounded  font-bold py-2 px-2 rounded text-xs  items-center hidden md:flex select-none'>
              <FaCrown className='mr-2 ' color='gold' /> Pro
            </p>
          )}

          <Link to='/app/profile'>
            {!user.photoURL ? (
              // If user has no photo, show initials
              <button className='rounded-full mx-3 w-12 h-12 border p-1 flex items-center justify-center bg-gray-50 '>
                <span className='text-blue-500 font-bold text-sm'>
                  {userInitials}
                </span>
              </button>
            ) : (
              <img
                src={user.photoURL}
                className='rounded-full mx-3 w-12 h-12 border p-1'
                alt={userInitials}
                referrerPolicy='no-referrer'
              />
            )}
          </Link>

          {/* Desktop Only */}
          <div className='hidden md:flex'>
            <SettingsMenu />
          </div>

          {/* Mobile Only */}
          {isLoggedIn && (
            <div className='md:hidden'>
              <Navdrawer />
            </div>
          )}
        </div>
      )}
    </header>
  )
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.auth?.user,
    subscription: state.db.db.subscription
  }
}

export default connect(mapStateToProps)(Header)
