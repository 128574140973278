import React, { useState, useEffect } from 'react'
import { navigate, Link } from '@reach/router'
import { connect } from 'react-redux'
import { loginWithEmail, loginWithGoogle } from '../../state/actions/auth'
import { StaticImage } from 'gatsby-plugin-image'
import { sendPasswordReset } from '../../state/actions/auth'

import { MdArrowBackIosNew } from 'react-icons/md'
import { FcGoogle } from 'react-icons/fc'
import { FaEnvelope } from 'react-icons/fa'

const Login = ({ login, isLoggedIn, loginWithGoogle, sendPasswordReset }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [useEmail, setUseEmail] = useState(false)

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/app/dashboard`)
    }
  }, [isLoggedIn])

  const handleLogin = e => {
    e.preventDefault()
    login({
      email,
      password
    })
  }

  return (
    <div className='bg-gradient-to-b from-[#060317] via-[#02096E] via-[#030756] to-[#050534]'>
      <div className=' h-screen w-screen  relative bg-white p-5 md:px-30 md:py-20  text-center rounded flex flex-col justify-center relative z-10'>
        {/* Content */}
        <div>
          {/* Icon */}
          <StaticImage
            src='../../images/icon1.png'
            alt=''
            placeholder='blurred'
            layout='fixed'
            width={60}
            className='mx-auto mb-2'
          />
          {/* Back Button */}
          <div className='absolute top-10 left-10 text-gray-600 z-10 tracking-wide font-bold hover:text-blue-400 duration-50'>
            <Link to='/' className=' flex gap-2 items-center'>
              <MdArrowBackIosNew className='text-sm' />
              Back
            </Link>
          </div>

          <h1 className='text-2xl font-bold'>Login</h1>
          <p className='text-sm mt-2'>Please sign-in to continue</p>

          {!useEmail && (
            <>
              <div className='absolute top-10 left-10 text-gray-600 z-10 tracking-wide font-bold hover:text-blue-400 duration-50'>
                <Link to='/' className=' flex gap-2 items-center'>
                  <MdArrowBackIosNew className='text-sm' />
                  Back
                </Link>
              </div>

              {/* Button to sign in with google */}
              <button
                className='mt-5 mx-auto px-0  min-w-[280px] flex items-center  justify-center border rounded-sm py-3 px-5 text-gray-500 hover:bg-gray-100 hover:border-white duration-200'
                onClick={e => {
                  e.preventDefault()
                  loginWithGoogle()
                }}
              >
                <span className='mr-2 text-xl'>
                  <FcGoogle />
                </span>
                Continue with Google
              </button>

              {/* Login with Email */}
              <button
                className='mt-5 mx-auto px-10 min-w-[280px] flex items-center   justify-center border rounded-sm py-3 px-5 text-gray-500 hover:bg-gray-100 hover:border-white duration-200'
                onClick={e => {
                  e.preventDefault()
                  setUseEmail(true)
                }}
              >
                <span className='mr-2 text-xl'>
                  <FaEnvelope color='#d3d3d3' />
                </span>
                Login with Email
              </button>

              {/* Separator */}
              <div className='mt-5 max-w-[300px] mx-auto'>
                <div className='border-dotted border-b-2 w-full mr-3'></div>
              </div>

              <p className='mt-5 text-sm '>
                Don't have an account?{' '}
                <Link to='/app/signup' className='text-blue-500'>
                  Sign Up
                </Link>
              </p>
            </>
          )}

          {useEmail && (
            <>
              <button
                onClick={() => {
                  setUseEmail(false)
                }}
                className='absolute top-10 left-10 text-gray-600 z-10 tracking-wide font-bold hover:text-blue-400 duration-50 flex gap-2 items-center'
              >
                <MdArrowBackIosNew className='text-sm' />
                Back
              </button>
              <form className='text-left max-w-[280px] mx-auto mt-5'>
                <div className='flex flex-col mt-5 '>
                  <label
                    htmlFor='email'
                    className='text-left text-gray-500 text-sm'
                  >
                    Email
                  </label>
                  <input
                    type='email'
                    className='border border-gray-300 p-3 rounded mt-2'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>

                <div className='flex flex-col mt-5'>
                  <label
                    htmlFor='password'
                    className='text-left text-gray-500 text-sm'
                  >
                    Password
                  </label>
                  <input
                    type='password'
                    className='border border-gray-300 p-3 rounded mt-2'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>

                <button
                  type='submit'
                  className='bg-purple-500 text-white px-5 py-3 rounded mt-5 w-full disabled:opacity-50 '
                  onClick={handleLogin}
                  disabled={!email || !password}
                >
                  Sign In
                </button>

                {/* Cancel Button */}
                <button
                  className='bg-gray-100 text-gray-500 px-5 py-3 rounded mt-2 w-full'
                  onClick={e => {
                    e.preventDefault()
                    setUseEmail(false)
                  }}
                >
                  Cancel
                </button>

                {/* Separator */}
                <div className='flex items-center mt-5'>
                  <div className='border-dotted border-b-2 w-full mr-3'></div>
                  <div className='text-gray-500'>or</div>
                  <div className='border-dotted border-b-2 w-full ml-3'></div>
                </div>

                {/* Login with Google */}
                <button
                  className='mt-2 flex items-center  w-full justify-center border rounded-sm py-3 px-5 text-gray-500 hover:bg-gray-100 hover:border-white duration-200'
                  onClick={e => {
                    e.preventDefault()
                    loginWithGoogle()
                  }}
                >
                  <span className='mr-2 text-xl'>
                    <FcGoogle />
                  </span>
                  Login with Google
                </button>

                <p className='mt-5 text-sm '>
                  Don't have an account?{' '}
                  <Link to='/app/signup' className='text-blue-500'>
                    Sign Up
                  </Link>
                </p>

                <p className='mt-2 text-sm '>
                  Forgot your password?{' '}
                  <Link to='/app/reset-password' className='text-blue-500'>
                    Reset Password
                  </Link>
                </p>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: user => dispatch(loginWithEmail(user)),
    loginWithGoogle: () => dispatch(loginWithGoogle()),
    sendPasswordReset: () => dispatch(sendPasswordReset())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
