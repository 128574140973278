import React from 'react'
import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  if (!isLoggedIn) {
    return null
  } else {
    // Logged in
    return <Component {...rest} />
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

export default connect(mapStateToProps)(PrivateRoute)
