import { navigate } from "gatsby"
import React, { useEffect, useLayoutEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { chatGPT } from "../../state/actions/gpt3"
import Layout from "../layout"

// Icons

import { BiSend } from "react-icons/bi"

const ChatService = ({ chat, subscription }) => {
  const messagesEndRef = useRef()

  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState([
    {
      type: "Liyana",
      text: "Hi, I am Liyana. I am here to help you with your writing. What would you like to write about today?",
    },
  ])

  // useEffect(() => {
  //   // If user subscription is not active, redirect to subscription page
  //   if (!subscription.active) {
  //     toast.error("Please Subscribe To Use Chat", {
  //       toastId: "chat",
  //     })
  //     navigate("/app/subscribe")
  //   }
  // }, [])

  useEffect(() => {
    // If last message, and not from AI, show a loading icon after 600ms delay
    if (
      !!messages[messages.length - 1]?.type &&
      messages[messages.length - 1]?.type !== "Liyana"
    ) {
      setTimeout(() => {
        setLoading(true)
      }, 200)
    } else {
      setLoading(false)
    }
  }, [messages])

  const handleSubmit = async e => {
    e.preventDefault()
    if (message === "") {
      return
    }
    const newMessage = { type: "user", text: e.target[0].value }
    // Begin promise chain for updating state recursively
    return new Promise(async (resolve, reject) => {
      try {
        setMessage("")
        // Set state with current message value
        setMessages(prevMessages => [...prevMessages, newMessage])
        const response = await chat(newMessage.text)

        // Set updated messages state recursively with response from server call and resolve promise afterward
        setMessages(prevMessages => [
          ...prevMessages,
          {
            type: "Liyana",
            text:
              response.replace("Liyana: ", "") ||
              "Sorry, I did not understand that. Could you please try again?",
          },
        ])

        resolve()
      } catch (err) {
        console.log(err)
        reject()
      }
    })
  }

  // Use useLayoutEffect to ensure that the scrolling occurs synchronously after the DOM has been updated
  useLayoutEffect(() => {
    const element = document.getElementsByClassName("chat-window")[0]
    element.scrollTop = element.scrollHeight
  }, [messages, loading])

  return (
    <Layout padding={0}>
      <div className="flex flex-col h-full overflow-y-hidden">
        {/* Chat UI */}
        <div className="flex flex-col max-h-[100%] overflow-y-hidden h-full">
          <div className="chat-window h-full overflow-y-scroll p-5 lg:pb-3 lg:px-8">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`${
                  message.type === "user"
                    ? "bg-blue-500 ml-auto text-white rounded-br-none "
                    : "bg-gray-200 rounded-bl-none "
                } px-4 py-3 rounded-xl md:max-w-[80%] w-fit mb-4`}
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {message.text}
              </div>
            ))}

            {/* If last message, and not from AI, show a loading icon */}
            {loading && (
              <p
                className={
                  "message bg-gray-200 rounded-bl-none px-4 py-3 rounded-xl md:max-w-[80%] w-fit mb-4"
                }
              >
                {/* Loading Icon */}
                <svg className="h-6 w-6">
                  <circle fill="gray" stroke="none" cx="3" cy="10" r="3">
                    <animate
                      attributeName="opacity"
                      dur="1s"
                      values="0;1;0"
                      repeatCount="indefinite"
                      begin="0.1"
                    />
                  </circle>
                  <circle fill="gray" stroke="none" cx="12" cy="10" r="3">
                    <animate
                      attributeName="opacity"
                      dur="1s"
                      values="0;1;0"
                      repeatCount="indefinite"
                      begin="0.2"
                    />
                  </circle>
                  <circle fill="gray" stroke="none" cx="20" cy="10" r="3">
                    <animate
                      attributeName="opacity"
                      dur="1s"
                      values="0;1;0"
                      repeatCount="indefinite"
                      begin="0.3"
                    />
                  </circle>
                </svg>
              </p>
            )}

            {/* This is the div that will be scrolled to */}
            <div ref={messagesEndRef} />
          </div>
        </div>

        {/* Form */}
        <form
          className="flex items-center sticky bottom-0 bg-gray-100 py-3 pl-3 md:pl-0"
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            placeholder="Type your message here"
            className="rounded-full w-[95%] mx-auto p-2 focus:outline-[#bb86fc]"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <button
            type="submit"
            className="text-gray-400 text-2xl pl-2 pr-3 py-2 hover:text-[#bb86fc] cursor-pointer"
            disabled={!message}
          >
            <BiSend />
          </button>
        </form>
      </div>
    </Layout>
  )
}

// Redux
const mapStateToProps = state => {
  return {
    subscription: state.db.db.subscription,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    chat: messages => dispatch(chatGPT(messages)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatService)
