import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'

import { getResponse } from '../../state/actions/gpt3'

const ThankYouNote = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)

    // Get Response from GPT-3
    // Write a prompt with the following information:
    // 1. The occasion:
    // 2. The person you are thanking:
    // 3. What you are thanking them for:
    // 4. Any specific details you would like to include:
    // 5. Any additional information you'd like to convey:

    const phrase = `Write a thank you note to ${data.recipient} for ${data.reason} in a ${data.tone} tone. Include ${data.details}.
    `
    const response = await getResponse({
      phrase,
      maxLength: 350,
      title: `Thank you note to ${data.recipient}`
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: `Thank you note to ${data.recipient}`,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  const handleTranscript = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('reason', transcript)
  }

  const handleTranscript2 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('recipient', transcript)
  }

  const handleTranscript3 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('details', transcript)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Occasion */}
          <div className='mt-4'>
            <label htmlFor='reason' className='  mb-1 '>
              <h3 className='text-gray-900 text-xl font-bold'>
                Reason For The Note
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Please provide some context of the reason for this note
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: After a business meeting, for receiving a gift, etc.'
                {...register('reason', {
                  required: 'Please enter the reason for the note'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>
          </div>

          {/* Recipient */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>Recipient</h3>
              <p className='text-sm text-gray-500 mb-2'>
                The person you are thanking
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: John Doe'
                {...register('recipient', {
                  required: 'Please enter the recipient of the wishes'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>

            {errors.recipient?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.recipient?.message}
              </p>
            )}
          </div>

          {/* Any specific details */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Specific Details
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Any specific details you would like to include?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Thank you for your help with the project'
                {...register('details')}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript3} />
              </div>
            </div>
          </div>

          {/* Tone */}
          <div className='mt-4'>
            <label htmlFor='tone' className='  mb-1 '>
              <h3 className='text-gray-900 text-l font-bold'>Tone</h3>
              <p className='text-sm text-gray-500 mb-2'>
                How would you like the note to sound?
              </p>
            </label>
            <select
              {...register('tone', { required: true })}
              placeholder='Select a tone'
              defaultValue='friendly'
              className='p-3 w-full border border-gray-300 rounded mt-2 text-sm'
            >
              <option value='friendly'>Friendly</option>
              <option value='professional'>Professional</option>
              <option value='funny'>Funny</option>
              <option value='romantic'>Romantic</option>
              <option value='formal'>Formal</option>
              <option value='informal'>Informal</option>
              <option value='business'>Business</option>
            </select>
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(ThankYouNote)
