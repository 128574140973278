import React from 'react'
import { Link } from 'gatsby'
import services from '../data/services'
import homeIcon from '../images/icons/home.png'
import chatIcon from '../images/services/chat.png'

const NavBar = () => {
  const navbarHeight = '96px'
  return (
    <nav
      className='bg-white w-2/12 font-semibold font-medium hidden xl:block overflow-y-scroll scroll'
      style={{
        height: `calc(100vh - ${navbarHeight})`,
        listStyle: 'none'
      }}
    >
      <div className=' mt-2 px-4  hover:bg-blue-100'>
        <Link className='flex items-center text-m py-3' to='/app/dashboard/'>
          <span className='text-blue-300'>
            <img src={homeIcon} className='w-5 h-5 ' alt='Home' />
          </span>
          <span className='ml-5 text-gray-700'>Dashboard</span>
        </Link>
      </div>

      <div className='px-4 hover:bg-blue-100'>
        <Link className='flex  items-center text-m py-3' to='/app/chat'>
          <span className='text-blue-300'>
            <img src={chatIcon} className='w-5 h-5 ' alt='Home' />
          </span>
          <span className='ml-5 text-gray-700'>Chat</span>
          <span className='ml-2 text-gray-700 text-xs text-red-600'>(New)</span>
        </Link>
      </div>

      <div className='text-gray-500 text-sm px-5 py-4'>Writing Tools</div>

      {services.map((service, idx) => {
        if (service.name === 'Chat') return null
        return (
          <li key={idx} className='  hover:bg-blue-50'>
            <Link
              className='flex items-center text-sm py-3 px-3'
              to={service.path}
              activeClassName='bg-blue-100'
            >
              <img
                src={service.image}
                className='w-5 h-5 '
                alt={service.name}
              />
              <span className='ml-5 text-gray-700'>{service.name}</span>
            </Link>
          </li>
        )
      })}
    </nav>
  )
}

export default NavBar
