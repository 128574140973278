import React, { useState } from 'react'
import priceData from '../data/pricing'
import { FaChessQueen, FaCheck, FaCrown } from 'react-icons/fa'
import Layout from './layout'
import { connect } from 'react-redux'
import { subscribe } from '../state/actions/db'
import { navigate } from '@reach/router'

function Subscribe ({ subscribe, subscription }) {
  const [pricing, setPricing] = useState('monthly')
  const navbarHeight = '96px'
  const footerHeight = '36px'

  const handleSubscribe = idx => {
    // If the user already has a subscription, navigate to billing section
    if (subscription.status === 'active') {
      window.open(
        process.env.GATSBY_STRIPE_BILLING_URL,
        '_blank',
        'noopener,noreferrer'
      )
    } else {
      if (idx === 0) {
        navigate('/app/dashboard/')
      } else {
        if (pricing === 'annual') {
          subscribe(idx + 2)
        } else {
          subscribe(idx)
        }
      }
    }
  }

  return (
    <Layout>
      <div
        className='container mx-auto flex flex-col justify-center'
        style={{
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - 5rem )`
        }}
      >
        <div className='items-center mb-10 text-center '>
          <div className=''>
            <h1 className='font-bold text-2xl'>Subscribe to Liyana</h1>
            <p className='text-gray-700 mt-2 text-sm'>
              Below you'll find a summary of this month's usage. Data may be
              delayed up to 5 minutes.
            </p>
          </div>
        </div>

        {/* Select Monthly or Annual Pricing */}
        <div className='flex justify-center items-center mt-5 gap-5'>
          <button
            className={`   ${
              pricing === 'monthly'
                ? `text-white bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C] `
                : `
             text-gray-900 `
            }
            py-2 px-10 uppercase rounded font-bold
          
          `}
            onClick={() => setPricing('monthly')}
          >
            Monthly
          </button>

          <button
            className={`   ${
              pricing === 'annual'
                ? `text-white bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C] `
                : `
               text-gray-900 `
            }
              py-2 px-10 uppercase rounded font-bold
        `}
            onClick={() => setPricing('annual')}
          >
            Annual
          </button>
        </div>

        {/* Cards */}
        <div className='flex flex-col md:flex-row justify-center items-center mt-10 gap-10 '>
          {priceData.map((plan, idx) => (
            <div
              className='drop-shadow relative min-w-[300px] min-w-[300px] min-h-[450px] text-white'
              key={idx}
            >
              {/* Background with transparent */}
              <div className='absolute top-0 left-0 w-full h-full  bg-gradient-to-b from-[#CA499A] to-[#BE3ED2] rounded-lg shadow-lg z-0'></div>

              <div className='relative z-10'>
                <div className='px-6 pt-8 relative'>
                  <div className='flex justify-between items-center'>
                    <h1 className='self-start text-xl font-bold'>
                      {plan.title}
                    </h1>
                    {/* Crown */}
                    {plan.title === 'Pro' && <FaCrown color='#DCFF04' />}
                    {plan.title === 'Starter' && (
                      <FaChessQueen color='#DCFF04' />
                    )}
                  </div>
                  <div className='my-8 text-center'>
                    <p className='text-4xl font-bold '>
                      {pricing === 'monthly'
                        ? `$${plan.monthly} `
                        : `$${plan.annual} `}
                      <span className='text-sm'>
                        {' '}
                        {pricing === 'monthly' ? `/month` : `/year`}
                      </span>
                    </p>
                  </div>

                  <button
                    className='drop-shadow-lg  bg-white text-black text-xs uppercase mx-auto font-bold py-2  rounded-lg mx-auto w-full mb-6'
                    onClick={() => {
                      handleSubscribe(idx)
                    }}
                  >
                    {idx === 0 ? 'Free' : 'Subscribe'}
                  </button>
                </div>

                {/* Line Separator */}
                <div className='w-full h-0.5 bg-white my-1 '></div>

                <div className='px-6 py-6 mb-10'>
                  <ul className=''>
                    {plan.features.map((feature, idx) => (
                      <li
                        className='flex items-center gap-4 text-sm mb-3'
                        key={idx}
                      >
                        {/* Check Icon */}
                        <span className='text-xs'>
                          <FaCheck />
                        </span>
                        <p>{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* Add stylistic white border at the bottom */}
              <div className='absolute bottom-0 left-0 w-full h-2 bg-white rounded-b'></div>
            </div>
          ))}
        </div>

        {/* Footer */}
        <div className='flex items-center container mx-auto mt-10 text-sm '>
          <p className='w-full text-gray-900 text-center  rounded-lg mx-10 p-2'>
            Need a Custom Enterprise Plan?{' '}
            <span className=' ml-1 underline cursor-pointer'>Contact us</span>
          </p>
        </div>
      </div>
    </Layout>
  )
}

// Redux
const mapStateToProps = state => {
  return {
    subscription: state.db.db.subscription
  }
}

const mapDispatchToProps = dispatch => {
  return {
    subscribe: itemId => dispatch(subscribe(itemId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe)
