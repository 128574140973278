import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'

import { getResponse } from '../../state/actions/gpt3'

const SalesPitch = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)

    // Get Response from GPT-3
    // Write a prompt with the following information:
    // • The position you are applying for
    // • A description of your qualifications and experience
    // • A description of the company you are applying to
    // • Your desired outcomes for the letter
    // • Any specific details you would like included in the letter

    const phrase = `Write a cover letter for the position of ${
      data.position
    }. My qualifications and experience include ${
      data.qualifications
    }. I am applying to ${data.company}. ${
      data.details && `I would like to include ${data.details}.`
    }`

    console.log(phrase)

    const response = await getResponse({
      phrase,
      maxLength: 800,
      title: `Cover Letter - ${data.position}}`
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: `Cover Letter - ${data.position}`,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  const handleTranscript = transcript => {
    setValue('position', transcript)
  }

  const handleTranscript2 = transcript => {
    setValue('qualifications', transcript)
  }

  const handleTranscript3 = transcript => {
    setValue('company', transcript)
  }

  const handleTranscript4 = transcript => {
    setValue('details', transcript)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Position you are applying for */}
          <div className='w-full'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-xl text-gray-900 font-bold mb-1'>Position</h3>
              <p className='text-sm text-gray-500 mb-2'>
                What position are you applying for?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Sales Manager'
                {...register('position', {
                  required: 'Please enter the position you are applying for'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>

            {errors.position?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.position?.message}
              </p>
            )}
          </div>

          {/* Qualifications & Experience */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Qualifications & Experience
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What are your qualifications and experience?
              </p>
            </label>

            <div className='relative'>
              <textarea
                placeholder='Example: Bachelor’s degree in Marketing'
                {...register('qualifications', {
                  required: 'Please enter your qualifications and experience'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>
            {errors.qualifications?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.qualifications?.message}
              </p>
            )}
          </div>

          {/* Company you are applying to */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Company you are applying to
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What company are you applying to?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Google'
                {...register('company', {
                  required: 'Please enter the company you are applying to'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />
              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript3} />
              </div>
            </div>
            {errors.company?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.company?.message}
              </p>
            )}
          </div>

          {/* Specific Details */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Specific Details
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Any specific details you wish to include?
              </p>
            </label>

            <div className='relative'>
              <textarea
                placeholder='Example: 3+ years of experience in sales'
                {...register('details')}
                className='p-3 w-full border border-gray-300 rounded'
              />
              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript4} />
              </div>
            </div>
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(SalesPitch)
