import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'

import { getResponse } from '../../state/actions/gpt3'

const SeasonsGreetings = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)
    // "Generate a season greeting wish that conveys happiness, joy, and warmth. The wish should be suitable for all ages and religions, and should be heartfelt and sincere."

    const phrase = `Generate a long season greeting wish for ${data.occasion} that conveys happiness, joy, and warmth. The wish should be suitable for all ages and religions, and should be heartfelt and sincere.
    `

    const response = await getResponse({
      phrase,
      maxLength: 350,
      title: `${data.occasion} wishes.`
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: `${data.occasion} wishes.`,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Occasion */}
          <div className='mt-4'>
            <label htmlFor='occasion' className='  mb-1 '>
              <h3 className='text-gray-900 text-xl font-bold'>
                What's The Occasion?
              </h3>
            </label>
            <select
              {...register('occasion', { required: true })}
              defaultValue='birthday'
              className='p-3 w-full border border-gray-300 rounded mt-2 text-sm'
            >
              <option value='Christmas'>Christmas</option>
              <option value='valentines day'>Valentine's Day</option>
              <option value='Easter'>Easter</option>
              <option value='halloween'>Halloween</option>
              <option value='new years day'>New Year's Day</option>
              <option value='independence day'>Independence Day</option>
              <option value='Eid al-Fitr'>Eid al-Fitr</option>
              <option value='Thanksgiving'>Thanksgiving</option>
              <option value='mothers day'>Mother's Day</option>
              <option value='fathers day'>Father's Day</option>
              <option value='fourth of july'>Fourth of July</option>
              <option value='memorial day'>Memorial Day</option>
              <option value='labour day'>Labour Day</option>
              <option value='good friday'>Good Friday</option>
              <option value='Hanukkah'>Hanukkah</option>
              <option value='Diwali'>Diwali</option>
            </select>
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(SeasonsGreetings)
