import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { WithContext as ReactTags } from 'react-tag-input'
import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'

import { getResponse } from '../../state/actions/gpt3'

const SalesLetter = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)

    // Get Response from GPT-3
    // Write a prompt with the following information:
    // 1. The name of the person who is being recommended
    // 2. The relationship of the person to the recommender
    // 3. Any relevant accomplishments or skills of the person
    // 4. The purpose of the recommendation letter
    // 5. The qualities or attributes that make the person stand out
    // 6. How the recommender knows the person
    // 7. A closing statement that reflects the recommender's confidence in the person's abilities.

    const phrase = `Write a long recommendation letter for ${
      data.recipient
    }. The relationship is ${data.relationship}. The purpose is ${
      data.purpose
    }. ${data.qualities && `Their qualities are ${data.qualities} in the ad.`}${
      data.tags && `Their skills and accomplishments include ${data.tags}.`
    }`

    const response = await getResponse({
      phrase,
      maxLength: 450,
      title: `${data.recipient} - Recommendation`
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: `${data.recipient} - Recommendation`,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  const handleTranscript = transcript => {
    setValue('recipient', transcript)
  }

  const handleTranscript2 = transcript => {
    setValue('relationship', transcript)
  }

  const handleTranscript3 = transcript => {
    setValue('accomplishments', transcript)
  }

  const handleTranscript4 = transcript => {
    setValue('purpose', transcript)
  }

  const handleTranscript5 = transcript => {
    setValue('qualities', transcript)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Company Name */}
          <div className='w-full'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-xl text-gray-900 font-bold mb-1'>
                Recipient
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Who are you recommending?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: John Smith'
                {...register('recipient', {
                  required:
                    'Please enter the name of the person you are recommending'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>

            {errors.recipient?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.recipient?.message}
              </p>
            )}
          </div>

          {/* Relationship */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Relationship
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is your relationship to the person you are recommending?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Co-worker'
                {...register('relationship', {
                  required: 'Please enter the relationship to the person'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>

            {errors.relationship?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.relationship?.message}
              </p>
            )}
          </div>

          {/* Accomplishments */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Accomplishments or Skills
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What are the relevant accomplishments or skills of the person
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: For a new job as a graphic designer'
                {...register('accomplishments', {
                  required: 'Please enter the purpose of the recommendation'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript3} />
              </div>
            </div>
          </div>

          {/* Purpose */}
          <div className='mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>Purpose</h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is the purpose of this recommendation?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: For a new job as a graphic designer'
                {...register('purpose', {
                  required: 'Please enter the purpose of the recommendation'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript4} />
              </div>
            </div>

            {errors.purpose?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.purpose?.message}
              </p>
            )}
          </div>

          {/* Qualities  */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Qualities or Attributes
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                The qualities or attributes that make the person stand out
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: Trustworthy", "hardworking", "creative'
                {...register('qualities', {
                  required: 'Please enter the qualities of the person'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript5} />
              </div>
            </div>
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(SalesLetter)
