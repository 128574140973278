import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { options } from './utils'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function App (props) {
  const data = {
    labels: props.labels,
    datasets: [
      {
        data: props.data,
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        pointBorderColor: '#111',
        pointBackgroundColor: '#ff4000',
        pointBorderWidth: 2
      }
    ]
  }
  //  Create new object from props.data according to day of the month

  return <Bar options={options} data={data} />
}

export default App
