import React, { useEffect, useState } from 'react'
import { navigate, Link } from '@reach/router'
import { useForm } from 'react-hook-form'
import { StaticImage } from 'gatsby-plugin-image'

import { connect } from 'react-redux'
import { signUpWithEmail, loginWithGoogle } from '../../state/actions/auth'
import { FcGoogle } from 'react-icons/fc'
import { MdArrowBackIosNew } from 'react-icons/md'
import { FaEnvelope } from 'react-icons/fa'

const SignUp = ({ signUpWithEmail, isLoggedIn, loginWithGoogle, location }) => {
  const [useEmail, setUseEmail] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/app/dashboard`)
    }
  }, [isLoggedIn])

  const handleSignUp = () => {
    signUpWithEmail(getValues())
    reset()
  }

  return (
    <div className='bg-gradient-to-b from-[#060317] via-[#02096E] via-[#030756] to-[#050534]'>
      <div
        className='flex justify-center items-center bg-gradient-to-b relative overflow-hidden '
        style={{ minHeight: `calc(100vh)` }}
      >
        <div className='text-white z-0 '>
          {/* Background Image - Vector Object*/}
          <div className='absolute w-full bottom-0 left-0'>
            <StaticImage
              src='../../images/landing/object.png'
              alt=''
              placeholder='blurred'
              layout='fullWidth'
              className='floatAnimation2'
            />
          </div>
        </div>
        <div className=' h-screen w-screen  relative bg-white p-5 md:px-30 md:py-20  text-center rounded flex flex-col justify-center relative z-10'>
          {/* Content */}
          <div>
            {/* Icon */}
            <StaticImage
              src='../../images/icon1.png'
              alt=''
              placeholder='blurred'
              layout='fixed'
              width={60}
              className='mx-auto mb-2'
            />

            <h1 className='text-2xl font-bold'>Start Free Trial</h1>
            <p className='text-sm mt-1'>Start your free trial with Liyana!</p>

            {!useEmail && (
              <>
                <div className='absolute top-10 left-10 text-gray-600 z-10 tracking-wide font-bold hover:text-blue-400 duration-50'>
                  <Link to='/' className=' flex gap-2 items-center'>
                    <MdArrowBackIosNew className='text-sm' />
                    Back
                  </Link>
                </div>

                {/* Button to sign in with google */}
                <button
                  className='mt-5 mx-auto px-0  min-w-[280px] flex items-center  justify-center border rounded-sm py-3 px-5 text-gray-500 hover:bg-gray-100 hover:border-white duration-200'
                  onClick={e => {
                    e.preventDefault()
                    loginWithGoogle()
                  }}
                >
                  <span className='mr-2 text-xl'>
                    <FcGoogle />
                  </span>
                  Continue with Google
                </button>

                {/* Login with Email */}
                <button
                  className='mt-5 mx-auto px-10 min-w-[280px] flex items-center   justify-center border rounded-sm py-3 px-5 text-gray-500 hover:bg-gray-100 hover:border-white duration-200'
                  onClick={e => {
                    e.preventDefault()
                    setUseEmail(true)
                  }}
                >
                  <span className='mr-2 text-xl'>
                    <FaEnvelope color='#d3d3d3' />
                  </span>
                  Sign Up with Email
                </button>
              </>
            )}

            {useEmail && (
              <>
                <button
                  onClick={() => {
                    setUseEmail(false)
                  }}
                  className='absolute top-10 left-10 text-gray-600 z-10 tracking-wide font-bold hover:text-blue-400 duration-50 flex gap-2 items-center'
                >
                  <MdArrowBackIosNew className='text-sm' />
                  Back
                </button>
                <form
                  onSubmit={handleSubmit(handleSignUp)}
                  className='text-left max-w-[280px] mx-auto mt-5'
                >
                  <div className=''>
                    <label
                      htmlFor='title'
                      className='text-gray-500 mb-1 text-sm'
                    >
                      First Name
                    </label>
                    <input
                      {...register('firstName', {
                        required: '*First name is required',
                        min: 18,
                        max: 99
                      })}
                      className='px-3 py-2 w-full  border border-gray-300 rounded  mt-1'
                    />

                    {errors.firstName?.type && (
                      <p role='alert' className='text-orange-600 text-sm mt-1'>
                        {errors.firstName?.message}
                      </p>
                    )}
                  </div>

                  <div className='mt-5 '>
                    <label
                      htmlFor='title'
                      className='text-gray-500 mb-1 text-sm'
                    >
                      Last Name
                    </label>
                    <input
                      {...register('lastName', {
                        required: '*Last name is required',
                        min: 18,
                        max: 99
                      })}
                      className='px-3 py-2 w-full  border border-gray-300 rounded  mt-1'
                    />

                    {errors.lastName?.type && (
                      <p role='alert' className='text-orange-600 text-sm mt-1'>
                        {errors.lastName?.message}
                      </p>
                    )}
                  </div>

                  <div className='mt-5 '>
                    <label
                      htmlFor='title'
                      className='text-gray-500 mb-1 text-sm'
                    >
                      Email
                    </label>
                    <input
                      {...register('email', {
                        required: '*Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'invalid email address'
                        }
                      })}
                      className='px-3 py-2 w-full  border border-gray-300 rounded  mt-1'
                    />

                    {errors.email?.type && (
                      <p role='alert' className='text-orange-600 text-sm mt-1'>
                        {errors.email?.message}
                      </p>
                    )}
                  </div>

                  <div className='mt-5 '>
                    <label
                      htmlFor='title'
                      className='text-gray-500 mb-1 text-sm'
                    >
                      Password
                    </label>
                    <input
                      type='password'
                      {...register('password', {
                        required: '*Password is required',
                        minLength: {
                          value: 6,
                          message: 'Password must have at least 6 characters'
                        }
                      })}
                      className='px-3 py-2 w-full  border border-gray-300 rounded  mt-1'
                    />

                    {errors.password?.type && (
                      <p role='alert' className='text-orange-600 text-sm mt-1'>
                        {errors.password?.message}
                      </p>
                    )}
                  </div>

                  <button
                    type='submit'
                    className='bg-purple-500 text-white px-5 py-3 rounded mt-5 w-full'
                  >
                    Start Free Trial
                  </button>
                </form>
              </>
            )}

            {/* Separator */}
            <div className='mt-5 max-w-[300px] mx-auto'>
              <div className='border-dotted border-b-2 w-full mr-3'></div>
            </div>

            <p className='mt-5 text-sm text-center'>
              Already have an account?{' '}
              <Link to='/app/login' className='text-blue-500'>
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loginWithGoogle: () => dispatch(loginWithGoogle()),
    signUpWithEmail: data => dispatch(signUpWithEmail(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
