import React from 'react'

export default function serviceLayout ({ children }) {
  const navbarHeight = '96px'
  const footerHeight = '36px'
  return (
    <div
      className='bg-white p-2 md:p-5 rounded w-full h-full  shadow pb-10'
      style={{
        minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - 10rem )`
      }}
    >
      {children}
    </div>
  )
}
