import React from 'react'
import { connect } from 'react-redux'
import Layout from './layout'

export const Success = ({ user }) => {
  return (
    <Layout>
      <div className='flex flex-col h-full'>
        <h1 className='text-2xl font-bold text-center'>
          Thank you for your payment!
        </h1>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => ({
  user: state.auth
})

export default connect(mapStateToProps)(Success)
