import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'
import { defaultLength } from '../../data/forms'
import { WithContext as ReactTags } from 'react-tag-input'

import { getResponse } from '../../state/actions/gpt3'

const Article = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)
    // Get Response from GPT-3
    // Write a prompt with the following information:
    // Topic:
    // Target Audience:
    // Length:
    // Keywords:
    // Style/Tone:
    // Questions to Answer:
    const phrase = `Write a blog article about ${data.topic} in a ${
      data.tone
    } tone. The target audience is ${data.targetAudience}. ${
      data.keywords && `Try to include the keywords - ${data.keywords}.`
    }`

    const response = await getResponse({
      phrase,
      maxLength: 800,
      title: data.topic
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: data.topic,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  const handleTranscript = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('topic', transcript)
  }

  const handleTranscript2 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('targetAudience', transcript)
  }

  const handleTranscript3 = transcript => {
    // Set the value of the react-hook-form textarea to the transcript
    // https://react-hook-form.com/api/useform/setvalue
    setValue('keywords', transcript)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}

        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Topic */}
          <div className='w-full'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-xl text-gray-900 font-bold mb-1'>Topic</h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is your article going to be about?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: How to milk a cow'
                {...register('topic', {
                  required: 'Please enter a topic for your article'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>

            {errors.topic?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.topic?.message}
              </p>
            )}
          </div>
          {/* Target Audience */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Target Audience
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What's the target audience for this blog article?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: People who like to drink milk'
                {...register('targetAudience', {
                  required: 'Please enter your target audience'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>

            {errors.targetAudience?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.targetAudience?.message}
              </p>
            )}
          </div>
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>Keywords</h3>
              <p className='text-sm text-gray-500 mb-2'>
                What keywords should be included in the article?
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: "cow", "milk", "drinking milk'
                {...register('keywords')}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript3} />
              </div>
            </div>
          </div>
          {/* <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Questions to Answer
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What keywords should be included in the article?
              </p>
            </label>

            //  Questions to Answer 
            <Controller
              name='questions'
              control={control}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <ReactTags
                  value={value}
                  classNames={{
                    tagInputField:
                      'p-3 w-full border border-gray-300 rounded mb-2',
                    selected: `${questions.length > 0 && 'py-2 '}`,
                    tag: 'rounded bg-gray-200 text-gray-700 p-2 mr-2 mb-2',
                    remove: 'font-bold text-gray-500 ml-2 hover:text-red-700'
                  }}
                  placeholder='Example: "Where do cows live?", "Is it hard to do?"'
                  tags={questions}
                  handleDelete={handleDeleteQuestion}
                  handleAddition={handleAddQuestion}
                  inputFieldPosition='top'
                  autocomplete
                  allowDragDrop={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                />
              )}
            />
            {errors.questions?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.questions?.message}
              </p>
            )}
          </div> */}
          {/* Tone */}
          <div className='mt-4'>
            <label htmlFor='tone' className='  mb-1 '>
              <h3 className='text-gray-900 font-bold'>Tone of Voice</h3>
              <p className='text-sm text-gray-500 mb-2'>
                What tone of voice should Liyana use?
              </p>
            </label>
            <select
              {...register('tone', { required: true })}
              defaultValue='professional'
              className='p-3 w-full border border-gray-300 rounded mt-2 text-sm'
            >
              <option value='professional'>Professional</option>
              <option value='creative'>Creative</option>
              <option value='friendly'>Friendly</option>
              <option value='funny'>Funny</option>
              <option value='romantic'>Romantic</option>
              <option value='formal'>Formal</option>
              <option value='informal'>Informal</option>
              <option value='business'>Business</option>
            </select>
          </div>
          {/* 
          // Range selector for number of words to generate
          <div className='mt-4'>
            <label htmlFor='words' className='mb-1'>
              <h3 className='text-gray-900 font-bold'>Max Length</h3>

              <p className='text-sm text-gray-500 mb-2'>
                Liyana won't generate more than {parseInt(range * 0.9)} words
              </p>
            </label>

            // Range Selector 
            <input
              {...register('range', { required: true })}
              type='range'
              min='100'
              max='1000'
              step='25'
              defaultValue={defaultLength}
              onChange={e => {
                setRange(e.target.value)
              }}
              className='w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg '
            />
          </div> */}
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(Article)
