import React from 'react'
import LottieIcon from '../lottieIcon'
import loadingIcon from '../../images/icons/clock.json'
import { TypeAnimation } from 'react-type-animation'

const interval = 1000
let responses = [
  'Hustling to get to you',
  'Sprinting to the finish line',
  'Climbing a mountain for you',
  'Lifting heavy weights',
  'Running a marathon for you',
  'Swimming against the current',
  'Breaking a sweat for you',
  'Pushing its limits for you',
  'Giving it its all for you',
  'Leaving it all on the field for you'
]

let sequence = []
responses.forEach(response => {
  sequence.push(response)
  sequence.push(interval)
})

export default function ResponseLoader () {
  return (
    <div className='m-auto'>
      <LottieIcon icon={loadingIcon} className='m-auto' />
      <TypeAnimation
        sequence={sequence}
        deletionSpeed={0}
        className='text-center text-xs'
        //  Replacing previous Text
        wrapper='span'
        repeat={Infinity}
      />
    </div>
  )
}
