import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { Link } from '@reach/router'
import { FaStar } from 'react-icons/fa'
import { HiMenu } from 'react-icons/hi'

import { StaticImage } from 'gatsby-plugin-image'
import { FaHome } from 'react-icons/fa'
import { AiOutlineLogin } from 'react-icons/ai'
import { connect } from 'react-redux'
import { signOut } from '../state/actions/auth'
import services from '../data/services'

const styles = {
  paper: {
    top: 0,
    width: 260
  }
}

class TemporaryDrawer extends React.Component {
  state = {
    left: false
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    })
  }

  render () {
    const { classes } = this.props
    const sideList = (
      <div className='navdrawer'>
        <div className='p-5'>
          {/* Logo */}
          <StaticImage
            src='../images/logo/logo-b.png'
            alt='Liyana'
            placeholder='blurred'
          />
        </div>

        <nav
          className='bg-white font-semibold font-medium '
          style={{
            listStyle: 'none'
          }}
        >
          <div>
            <List>
              <Link to='/app/dashboard'>
                <ListItem button>
                  <h3 className='navdrawer__link'>
                    <FaHome /> Dashboard
                  </h3>
                </ListItem>
              </Link>

              {this.props.subscription?.status === 'inactive' && (
                <Link to='/app/subscribe'>
                  <ListItem button>
                    <h3 className='navdrawer__link'>
                      <FaStar /> Upgrade
                    </h3>
                  </ListItem>
                </Link>
              )}

              {this.props.isLoggedIn ? (
                <div
                  onClick={this.props.logout}
                  className='mt-auto'
                  role='button'
                  onKeyDown={this.props.logout}
                >
                  <ListItem button>
                    <h3 className='navdrawer__link'>
                      <AiOutlineLogin /> Logout
                    </h3>
                  </ListItem>
                </div>
              ) : (
                <Link to='/app/login'>
                  <ListItem button>
                    <h3 className='navdrawer__link'>
                      <AiOutlineLogin /> Login
                    </h3>
                  </ListItem>
                </Link>
              )}
            </List>
          </div>

          <div className='px-3'>
            <div className='text-gray-500 text-sm mt-2 mb-3'>Writing Tools</div>

            <ul>
              {services.map((service, idx) => {
                return (
                  <li key={idx} className='hover:bg-blue-50 py-2'>
                    <Link
                      className='flex items-center text-sm py-3 px-5'
                      to={service.path}
                    >
                      <img
                        src={service.image}
                        className='w-5 h-5 '
                        alt={service.name}
                      />
                      <span className='ml-5 text-gray-700'>{service.name}</span>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </nav>
      </div>
    )

    return (
      <div>
        <div
          id='nav-icon'
          className={this.state.right ? 'open' : 'close'}
          onClick={this.toggleDrawer('right', true)}
        >
          <HiMenu className='text-3xl text-gray-400' id='dropdownChild' />
        </div>
        <Drawer
          classes={{ paper: classes.paper }}
          anchor='right'
          open={this.state.right}
          onClose={this.toggleDrawer('right', false)}
        >
          <div
            tabIndex={0}
            role='button'
            onClick={this.toggleDrawer('right', false)}
            onKeyDown={this.toggleDrawer('right', false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    )
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    subscription: state.db.db.subscription
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(signOut())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TemporaryDrawer))
