import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaSpinner } from 'react-icons/fa'
import SpeechModal from '../speechModal'
import { connect } from 'react-redux'

import { getResponse } from '../../state/actions/gpt3'
import countries from '../../data/countries'

const SalesPitch = ({
  responseHandler,
  loadingHandler,
  isLoading,
  getResponse
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // Clear input & set loading state
    responseHandler('')
    loadingHandler(true)

    // Get Response from GPT-3
    // Write a prompt with the following information:
    // -Topic of video (e.g. travel, cooking, health, etc)
    // -Target audience (e.g. teens, young adults, seniors, etc)
    // -Video length (e.g. 2 minutes, 5 minutes, 10 minutes, etc)
    // -Objective (e.g. to educate, to entertain, to inform, etc)
    // -Any additional elements or requirements (e.g. interview subjects, visual effects, etc)

    const phrase = `Write a script for a YouTube video about ${
      data.topic
    }. The theme of the video is ${data.objective}. ${
      data.additionalInfo && `Additional information: ${data.additionalInfo}`
    }`

    const response = await getResponse({
      phrase,
      maxLength: 2000,
      title: data.topic
    })

    if (response === false) {
      loadingHandler(false)
      return
    }

    // Set response in component state (parent) from response
    responseHandler({
      title: data.topic,
      response: response
    })

    // Set loading state & reset form
    loadingHandler(false)
  }

  const handleTranscript = transcript => {
    setValue('topic', transcript)
  }

  const handleTranscript2 = transcript => {
    setValue('additionalInfo', transcript)
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-start '
      >
        {/* Flex show Article Icon and Title */}
        <div className='grid grid-cols-1 w-full mb-4'>
          {/* Topic */}
          <div className='w-full'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-xl text-gray-900 font-bold mb-1'>Topic</h3>
              <p className='text-sm text-gray-500 mb-2'>
                What is the topic of your video? (e.g. travel, cooking, health,)
              </p>
            </label>

            <div className='relative'>
              <input
                placeholder='Example: How to milk a cow'
                {...register('topic', {
                  required: 'Please enter a topic for your article'
                })}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript} />
              </div>
            </div>

            {errors.topic?.type && (
              <p role='alert' className='text-red-500 text-sm mt-1'>
                {errors.topic?.message}
              </p>
            )}
          </div>

          {/* Theme */}
          <div className='mt-4'>
            <label htmlFor='title' className='mb-1'>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Theme of Video
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                What type of video do you want to create?
              </p>
            </label>

            <select
              {...register('theme', { required: true })}
              defaultValue='2'
              className='p-3 w-full border border-gray-300 rounded mt-2 text-sm'
            >
              <option value='2'>Informative</option>
              <option value='5'>Educational</option>
              <option value='10'>Entertaining</option>
            </select>
          </div>

          {/* Any additional */}
          <div className='w-full mt-4'>
            <label htmlFor='title' className='  mb-1 '>
              <h3 className='text-l text-gray-900 font-bold mb-1'>
                Additional Information
              </h3>
              <p className='text-sm text-gray-500 mb-2'>
                Any additional elements or requirements (e.g. interview
                subjects, visual effects, etc)
              </p>
            </label>

            <div className='relative'>
              <textarea
                lines='3'
                placeholder='Example: I want to include a dog in the video'
                {...register('additionalInfo')}
                className='p-3 w-full border border-gray-300 rounded'
              />

              <div className='absolute right-3 bottom-4' id='yourAppElement'>
                <SpeechModal handleTranscript={handleTranscript2} />
              </div>
            </div>
          </div>
        </div>

        {/* Blue button */}
        <button
          className='mt-5 px-5 py-3 rounded bg-gradient-to-b from-purple-500  to-purple-600 hover:bg-gradient-to-br  text-white text-center disabled:opacity-60 w-full md:w-64 h-12 duration-200'
          type='submit'
          disabled={isLoading || Object.keys(errors).length > 0}
        >
          {isLoading ? (
            <p className='flex justify-center items-center gap-1'>
              <FaSpinner className='spinner mr-1 text-sm' /> Loading..
            </p>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  )
}

// Connect to redux thunk
const mapDispatchToProps = dispatch => ({
  getResponse: data => dispatch(getResponse(data))
})

export default connect(undefined, mapDispatchToProps)(SalesPitch)
