import React, { useEffect, useState } from 'react'

import Layout from './layout'
import ServiceLayout from './services/serviceLayout'
import { connect } from 'react-redux'
import { getUsageHistory } from '../state/actions/db'
import BarChart from './charts/barChart'
import LineChart from './charts/lineChart'
import Doughnut from './charts/doughnut'

import { format, differenceInDays, addDays } from 'date-fns'

import _ from 'lodash'
import { getDate } from 'date-fns'

function Usage ({
  getUsageHistory,
  usageHistory,
  serverTimestamp,
  wordLimit,
  totalUsage = 0
}) {
  const [labels, setLabels] = useState([])
  const [usage, setUsage] = useState('daily')
  const navbarHeight = '96px'
  const footerHeight = '36px'

  useEffect(() => {
    if (!serverTimestamp) {
      return
    }
    const days = []
    const today = new Date(serverTimestamp)
    const firstDateOfMonth = format(today, 'yyyy-MM-01')
    const lastDateOfMonth = format(today, 'yyyy-MM-dd')

    let dateStart = firstDateOfMonth
    const dateEnd = lastDateOfMonth
    let noOfDays = differenceInDays(new Date(dateEnd), new Date(dateStart))

    while (noOfDays >= 0) {
      days.push(format(new Date(dateStart), 'dd MMM'))
      dateStart = format(addDays(new Date(dateStart), 1), 'yyyy-MM-dd')
      noOfDays--
    }
    setLabels(days)
  }, [serverTimestamp])

  useEffect(() => {
    getUsageHistory()
  }, [])

  const dailyData = []

  _.forEach(usageHistory, item => {
    const date = new Date(item.timestamp)
    const day = getDate(date)

    if (dailyData[day - 1]) {
      dailyData[day - 1] += item.usage
    } else {
      dailyData[day - 1] = item.usage
    }
  })

  //   Cumulative Data
  let cumulativeData = []
  const orderedData = _.orderBy(usageHistory, ['timestamp'], ['asc'])
  let total = 0

  _.forEach(orderedData, item => {
    const date = new Date(item.timestamp)
    const day = getDate(date)
    const daysInMonth = getDate(serverTimestamp)

    for (let i = day - 1; i < daysInMonth; i++) {
      if (day - 1 === i) {
        total += item.usage
        cumulativeData[i] = total
      } else {
        cumulativeData[i] = total
      }
    }
  })

  function replaceEmptyWith (arr, val) {
    var _arr = []
    if (arr && arr.length > 0) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] === undefined) _arr[i] = val
        else _arr[i] = arr[i]
      }
      return _arr
    }
    return arr
  }

  cumulativeData = replaceEmptyWith(cumulativeData, '')

  return (
    <Layout>
      <div
        className='container mx-auto'
        style={{
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - 5rem )`
        }}
      >
        <div className='items-center mb-10 '>
          <div className=''>
            <h1 className='font-bold text-2xl'>Usage</h1>
            <p className='text-gray-700 mt-2 text-sm'>
              Below you'll find a summary of this month's usage. Data may be
              delayed up to 5 minutes.
            </p>
          </div>
        </div>

        <div className='flex flex-col gap-10'>
          <ServiceLayout>
            {/* Switch between Daily Usage and Cumulative Usage */}
            <div className='flex items-center'>
              <button
                onClick={() => setUsage('daily')}
                className={`
            ${
              usage === 'daily'
                ? 'bg-blue-200 text-gray-700'
                : 'bg-blue-100 text-gray-500'
            }
            bg-gray-100
            hover:bg-blue-200
            text-gray-700
            py-2
            px-4
            rounded-l
            uppercase
            text-sm
            font-bold
          `}
              >
                Daily
              </button>
              <button
                className={`
            ${
              usage === 'cumulative'
                ? 'bg-blue-200 text-gray-700'
                : 'bg-blue-100 text-gray-500'
            }
            bg-gray-100
            hover:bg-blue-200
            text-gray-700
            py-2
            px-4
            rounded-l
            uppercase
            text-sm
            font-bold
            ml-2
          `}
                onClick={() => setUsage('cumulative')}
              >
                Cumulative
              </button>
            </div>

            {/* Graph */}
            <div className='w-full  h-80'>
              <div className=' flex items-center justify-center min-h-full '>
                <div className='flex flex-col lg:flex-row  w-full gap-20'>
                  <div className='w-full'>
                    {usage === 'daily' ? (
                      <BarChart data={dailyData} labels={labels} />
                    ) : (
                      <LineChart data={cumulativeData} labels={labels} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ServiceLayout>

          <ServiceLayout fit={true}>
            <div className='flex flex-col lg:flex-row gap-10 pb-2'>
              <div className='w-96'>
                <Doughnut
                  data={[totalUsage, Math.max(wordLimit - totalUsage, 0)]}
                  labels={['Red', 'Blue']}
                  text={Math.round((totalUsage / wordLimit) * 100) + '%'}
                />
              </div>
            </div>
          </ServiceLayout>
        </div>
      </div>
    </Layout>
  )
}

// connect to redux store
const mapStateToProps = state => {
  return {
    serverTimestamp: state.db?.db?.serverTimestamp,
    usageHistory: state.db?.db?.usageHistory,
    wordLimit: state.db?.db?.subscription?.plan?.wordCount,
    totalUsage: state.db?.db?.usage?.totalUsage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUsageHistory: () => dispatch(getUsageHistory())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Usage)
